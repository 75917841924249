import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { ButtonA, Button } from '../components/button'
import dlv from 'dlv'
import dset from 'dset'
import { TrailEls } from '../helpers'
// import VideoCover from 'react-video-cover'

import rainImg from '../images/testrain.png'

// import dripVid from '../video/drip.mp4'

import { HowForm } from '../forms.js'

import HowLongContent from './how-long-content'

const ThermometerIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 1698">
		<path fill="#0a4b6c" d="M374 1284h-10v-21h144v21z" />
		<path fill="#0a4b6c" d="M374 1213h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 1141h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 1070h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 999h-10v-21h144v21z" />
		<path fill="#0a4b6c" d="M374 928h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 856h-10v-20h79v20z" />
		<path fill="#0a4b6c" d="M374 785h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 714h-10v-21h144v21z" />
		<path fill="#0a4b6c" d="M374 643h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 571h-10v-20h79v20z" />
		<path fill="#0a4b6c" d="M374 500h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 429h-10v-21h144v21z" />
		<path fill="#0a4b6c" d="M374 358h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 286h-10v-20h79v20z" />
		<path fill="#0a4b6c" d="M374 215h-10v-21h79v21z" />
		<path fill="#0a4b6c" d="M374 144h-10v-21h144v21z" />
		<path
			fill="#0a4b6c"
			d="M195 0a89 89 0 0 1 89 89v1238c97 50 134 169 84 266a197 197 0 0 1-34 47c-76 77-199 78-276 2a197 197 0 0 1 49-315V89a89 89 0 0 1 88-89zm0 21a68 68 0 0 0-67 68v1244c0 4-3 8-6 10a175 175 0 1 0 249 158 174 174 0 0 0-101-158c-4-1-7-5-7-10V89a68 68 0 0 0-68-68z"
		/>
		<path
			fill="#d8dbe2"
			d="M195 56c-17 0-32 15-32 33v1273l-27 13a140 140 0 1 0 119 0l-27-13V89a33 33 0 0 0-33-33z"
		/>
		<path
			fill="#ff4242"
			d="M163 762v600l-27 13a140 140 0 1 0 119 0l-27-13V762z"
		/>
	</svg>
)

const HowPage = ({
	setDropletState,
	scrollbarWidth,
	ourFormData,
	setOurFormData,
	animateTransitions,
}) => {
	// const [answerVal, setAnswerVal] = useState()
	// const [continued, setContinued] = useState()

	const answerVal = dlv(ourFormData, 'how.answer')

	const continued = dlv(ourFormData, 'how.continued')
	const setContinued = state => {
		// clone our form data
		let newFormData = JSON.parse(JSON.stringify(ourFormData))
		// update w/ answer
		dset(newFormData, 'how.continued', state)
		setOurFormData(newFormData)
	}

	useEffect(() => {
		// update droplets
		if (!continued) {
			setDropletState([1, 1, 0, 0, 0])
		} else {
			setDropletState([1, 1, 0.5, 0, 0])
		}
	}, [continued])

	const initialViewTransition = useTransition(!answerVal, null, {
		initial: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const secondViewTransition = useTransition(answerVal && !continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const thirdViewTransition = useTransition(continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
	})

	return (
		<div className={`page page-how`}>
			{initialViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div key={key} style={props} className="page-sections">
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													How do <strong>fire sprinklers work?</strong>
												</h2>
											</div>,
											<div className="gradient-wrap">
												<div className="gw-inner">
													<HowForm
														answerVal={answerVal}
														ourFormData={ourFormData}
														setOurFormData={setOurFormData}
													/>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>Receive more information on installing fire sprinklers...</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{secondViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="page-sections view-two"
						>
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													How do <strong>fire sprinklers work?</strong>
												</h2>
											</div>,

											<div className="gradient-wrap">
												<div className="gw-inner second">
													<h3>We all deserve to be safe!</h3>
													<div className="answer-exp">
														<p>
															Every family is unique, but living with sprinklers
															provides more time to evacuate children, the
															elderly and disabled, or furry family members.
														</p>
													</div>
													<p>Continue to learn how fire sprinklers work</p>
													<Button
														className="btn btn-white"
														onClick={() => setContinued(true)}
													>
														Continue ››
													</Button>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>Receive more information on installing fire sprinklers...</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{thirdViewTransition.map(
				({ item, key, props }) =>
					item && (
						<React.Fragment key={key}>
							<animated.div
								style={props}
								className={`page-sections view-three`}
							>
								<div
									className="main ps-main"
									style={{
										backgroundImage: `url(${rainImg})`,
										overflow: 'hidden',
									}}
								>
									<div className="background-video">
										{/* <VideoCover
											videoOptions={{
												src: dripVid,
												autoPlay: true,
												muted: true,
												controls: false,
												loop: true
											}}
										/> */}
									</div>
									<div className="inner-wrap">
										<TrailEls
											animateTransitions={animateTransitions}
											items={[
												<div className="section-header">
													<h2 className="section-heading">
														How do <strong>fire sprinklers work?</strong>
													</h2>
												</div>,
												<div className="thermometer svg-wrap">
													<ThermometerIcon />
												</div>,
												<div className="view-three-content">
													<h2>
														Fire Sprinklers React to High Heat, <br />
														Providing Water Fast!
													</h2>
													<p>
														They are not set off by smoke or a fire alarm and
														they don’t deploy all at once. <br />
														Each sprinkler has a glass bulb that breaks at 135°
														F to 225° F, immediately releasing water. <br />
														About 90% of the time, a single fire sprinkler is
														all that’s needed to control a fire.{' '}
													</p>
													<ButtonA href="#learn_more" className="btn">
														Learn More
													</ButtonA>
												</div>,
											]}
										/>
									</div>
								</div>
								<div id="learn_more">
									<HowLongContent />
								</div>
							</animated.div>
							<Link
								to="/what"
								className="next-link"
								style={{ right: scrollbarWidth }}
							>
								Next
							</Link>
						</React.Fragment>
					)
			)}
		</div>
	)
}

export default HowPage
