import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSpring, useTransition, animated, config } from 'react-spring'

const MobileMenu = ({ showMobileMenu, setShowMobileMenu }) => {
	useEffect(() => {}, [window.innerWidth])

	const transitions = useTransition(showMobileMenu, null, {
		from: { position: 'absolute', opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: config.stiff,
	})
	const linkScale = useSpring({
		transform: showMobileMenu ? 'scale(1)' : 'scale(0.97)',
		// config: config.stiff
	})
	return transitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div
					className="mobile-menu"
					key={key}
					style={props}
					onClick={() => setShowMobileMenu(false)}
				>
					<animated.nav className="nav-links" style={linkScale}>
						<div className="nav-item">
							<NavLink exact to="/">
								Home
							</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/about">About</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/why">Why</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/how">How</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/what">What</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/who">Who</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/upkeep">Upkeep</NavLink>
						</div>
						<div className="nav-item">
							<NavLink to="/full-form">Full Form</NavLink>
						</div>
					</animated.nav>
				</animated.div>
			)
	)
}

export default MobileMenu
