import React, { useEffect } from 'react'

import { ButtonLink } from '../components/button'
// import { Link } from 'react-router-dom'

import rainBackgroundWhite from '../images/background-white-rain.jpg'
import rainBackgroundBlue from '../images/background-blue-rain.jpg'

const WhatLongContent = ({ setDropletState }) => {
	useEffect(() => {
		setDropletState && setDropletState([0, 0, 0, 0, 0])
	}, [])
	return (
		<div
			className="default-page about-long-content"
			style={{ backgroundImage: `url(${rainBackgroundWhite})` }}
		>
			<section className="def-lead">
				<div className="main">
					<div className="inner-wrap">
						<div className="section-header left-justify">
							<h2 className="section-heading">
								When can sprinklers be installed?{' '}
								<strong>And how much do they cost?</strong>
							</h2>
							<p>
								Sprinklers can be installed in any new or existing residence,
								though a single-family home with one owner is the easiest
								arrangement.
							</p>
							<p>
								Apartments and condos with multiple units require the
								cooperation of the building owner association or board. In
								essence, the more control you have over adding or changing
								piping – or any new build or renovation – the easier it is to
								opt for fire sprinklers.
							</p>
							<p>
								A new installation during construction is the most
								straightforward and cost-effective way to sprinkler a home. An
								installer will design the system while proactively accounting
								for your plumbing system, water supply, and the size and design
								of the home – and the pipe will be installed before any drywall
								goes up.
							</p>
							<p>
								Sprinklers can also be retrofitted into any existing residence,
								however. Qualified, licensed installers choose from different
								system types and water sources to find the most efficient way to
								add sprinklers, while creating minimal inconvenience and
								expense.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							<strong>Multipurpose vs. standalone</strong> residential fire
							sprinkler systems
						</h2>
						<div className="vf-vid">
							<div className="responsive-embed">
								<iframe
									title="Buy Time, Buy Life"
									loading="lazy"
									src="https://www.youtube.com/embed/Nd7dhL5QmZg?rel=0"
									frameBorder="0"
									allowFullScreen
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
						<div className="vf-content">
							<p>
								All homes – new or existing – can be equipped with fire
								sprinkler systems, which come in two basic varieties:
								multipurpose or standalone.
							</p>
							<p>
								<strong>Multipurpose systems</strong> share water and pipe with
								your home plumbing system. Because they make dual use of these
								pipes, installations of multipurpose systems tend to cost less
								than standalone systems. A 2013 study from the Fire Protection
								Research Foundation found that the average cost of multipurpose
								systems was less than the cost of standalone{' '}
								<span className="p-cite">
									systems.
									<span className="the-citations">
										<sup>1</sup>
									</span>
								</span>
							</p>
							<p>
								However, multipurpose systems can be difficult to install in an
								existing home. Existing plumbing may not be able to provide the
								volume or pressure required without an overhaul. And common
								plumbing fixtures, such as pressure-reducing valves or water
								softeners, can further reduce the pressure available to
								sprinkler heads.
							</p>
							<p>
								<strong>Standalone systems</strong>, on the other hand, are
								connected directly to the water main also supplying your
								separate plumbing system or to a water tank.
							</p>
							<p>
								If your local water supply lacks enough pressure, a pump or
								gravity tank can be added to supplement the system. While{' '}
								<i>slightly</i> more complex than multipurpose systems,
								standalone systems can be installed with fewer modifications to
								your home's plumbing or walls, making them particularly
								well-suited to retrofits.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="graphic-section left-justify">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							How much does a <strong>residential system cost?</strong>
						</h2>
						<p>
							NFPA 13D, the National Fire Protection Association’s standard for
							installing residential sprinklers, strives to make systems
							affordable.
						</p>
						<p>
							To that end, it allows for the omission of fire sprinklers in
							closets, bathrooms, ceiling pockets, and in other areas of a
							certain size. And professional maintenance inspections – while
							recommended – aren't required.
						</p>
						<p>
							Home sprinkler systems in new construction tend to add only one to
							two percent to the total price – less than the cost of a covered
							patio or window treatment. At the median US home price of
							$200,000, that is only $10 to $20 more a month on an average
							mortgage{' '}
							<span className="p-cite">
								payment.
								<span className="the-citations">
									<sup>2</sup>
									<sup>3</sup>
								</span>
							</span>
						</p>
						<div className="narrow-jumble nj-callout">
							<h3>
								<strong>
									$10 to $20 a month to protect your home and family
								</strong>
							</h3>
						</div>
						<p>
							Systems that use only plastic pipe reduce average costs by ten
							percent over systems using metallic pipe or a mix of both kinds.
							And those that rely on public water sources cost more than 20%
							less on average than systems using stored water tanks. NFPA 13D
							doesn't require backflow preventers – devices used to prevent
							contamination of drinkable water supplies – but when local laws
							require them, the standard allows them to be used as a control
							valve, eliminating one more part (and one more cost).
						</p>
						<p>
							Insurers look favorably on sprinklered homes, which experience
							$42,853 dollars less damage on average during a fire than homes
							without. A study by the Fire Protection Research Foundation found
							that insurers, on average, were willing to discount insurance
							premiums by 7% in sprinklered homes. With the national average
							premium at more than $1,150 a year, that discount saves $2,415
							over the course of a 30-year{' '}
							<span className="p-cite">
								mortgage.
								<span className="the-citations">
									<sup>4</sup>
									<sup>5</sup>
									<sup>6</sup>
								</span>
							</span>
						</p>
					</div>
				</div>
			</section>

			<section
				className="blue-two-col"
				style={{ backgroundImage: `url(${rainBackgroundBlue})` }}
			>
				<div className="main">
					<div className="inner-wrap">
						<h2>
							How do <strong>model codes</strong> influence the options I choose
							from?
						</h2>
						<div className="two-col-content">
							<div>
								<p>
									Regulatory requirements for home sprinklers vary from place to
									place – but almost all jurisdictions start with "model codes"
									from trusted safety organizations.
								</p>
								<p>
									The National Fire Protection Association (NFPA) and the
									International Code Council (ICC) – both leading organizations
									in the fields of fire prevention and safe building development
									– recognize that residential fire sprinklers are an essential
									part of life safety and property protection. The publications
									they develop, known as model codes, have been given the force
									of law by many state and local governments, governing the
									construction of buildings from houses to high-rises.
								</p>
							</div>
							<div>
								<p>
									<strong>
										After decades of development, both organizations'
										requirements now mandate fire sprinkler systems in
										newly-built homes and townhomes. New apartments, board and
										care, condominiums, care facilities, congregate living,
										hotels, group homes, vacation timeshare properties, and
										high-rises are also required to have fire sprinkler systems.
									</strong>{' '}
									While most jurisdictions require sprinklers in new residential
									commercial buildings, not all jurisdictions have adopted the
									model codes as law for single-family housing … yet.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="narrow-jumble left-justify">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-red-headline">
							A licensed sprinkler contractor can give you an{' '}
							<strong>accurate time and cost</strong> estimate
						</h2>
						<p className="ta-center">
							How much a sprinkler system costs depends on the size of the home,
							the type of water source, and where you live.
						</p>
						<p className="ta-center">
							For example, Maryland and California – which both require fire
							sprinklers in newly-built homes statewide – had average
							installation costs 25% lower than those in other states. As more
							and more governments follow the model codes and make sprinklers in
							new homes the law, the prices are coming{' '}
							<span className="p-cite">
								down.
								<span className="the-citations">
									<sup>7</sup>
								</span>
							</span>
						</p>
						<ButtonLink to="/who" className="btn">
							Learn about licensed sprinkler contractors and why you should use
							one ››
						</ButtonLink>
					</div>
				</div>
			</section>

			<section className="citations">
				<div className="main">
					<div className="inner-wrap">
						<h2>Sources:</h2>
						<ol>
							<li>
								“Home Fire Sprinkler Cost Assessment – 2013.” The Fire
								Protection Research Foundation.
							</li>
							<li>“Fire Sprinkler Systems.” City of Scottsdale, AZ.</li>
							<li>
								“Home Fire Sprinkler Cost Assessment – 2013.” The Fire
								Protection Research Foundation.
							</li>
							<li>
								“Home Safety.” The Home Fire Sprinkler Coalition via State Farm
								Insurance.
							</li>
							<li>
								"Home Fire Sprinkler Cost Assessment." Fire Protection Research
								Foundation. September 2008.
							</li>
							<li>
								"Facts + Statistics: Homeowners and renters insurance."
								Insurance Information Institute.
							</li>
							<li>
								“Home Fire Sprinkler Cost Assessment – 2013.” The Fire
								Protection Research Foundation.
							</li>
						</ol>
					</div>
				</div>
			</section>
		</div>
	)
}

export default WhatLongContent
