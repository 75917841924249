import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { ButtonA, Button } from '../components/button'
import dlv from 'dlv'
import dset from 'dset'
import { TrailEls } from '../helpers'
// import VideoCover from 'react-video-cover'

import rainImg from '../images/testrain.png'

import { WhoForm } from '../forms.js'

import WhoLongContent from './who-long-content'

const AwardIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.5 594.4">
		<path
			fill="#0a4b6c"
			d="M347.6 398c-4.8 3.5-11 5.3-18.6 5.3-3.3 0-6.4-.3-9.4-.6-2.6-.2-5-.4-7-.4a13 13 0 0 0-2.7.2 45.6 45.6 0 0 0-5.3 7.8c-4.5 7.5-10 16.7-20.5 20.1a25.6 25.6 0 0 1-7.9 1.2c-7.4 0-14.1-2.9-20.1-5.4-3-1.3-7.3-3.1-9.2-3.2-1.6.7-5.2 3.8-7.4 5.7A61.5 61.5 0 0 1 225 439l44.4 155.4 50-79.4 84.4 41-48-168a27.2 27.2 0 0 1-8.2 10z"
		/>
		<path
			fill="#0a4b6c"
			d="M180.6 423c-2 0-6.3 2-9.2 3.2-6 2.5-12.8 5.4-20.1 5.4a25.6 25.6 0 0 1-8-1.2c-10.4-3.4-16-12.7-20.4-20a47 47 0 0 0-5.3-8 13 13 0 0 0-2.7-.1c-2 0-4.4.2-7 .4-3 .3-6.2.6-9.4.6-7.7 0-13.8-1.8-18.6-5.3a27 27 0 0 1-8.3-10L23.7 556l84.4-41 50 79.3L202.5 439a61.5 61.5 0 0 1-14.5-10.3c-2.2-2-5.8-5-7.4-5.8z"
		/>
		<path
			fill="#ff4242"
			d="M213.7 57.5a156.3 156.3 0 0 0-8.4 312.3q4.2.3 8.4.3c2.9 0 5.7-.1 8.5-.3a156.3 156.3 0 0 0-8.5-312.4zm88.4 109l-73.4 127-.3.4a30.4 30.4 0 0 1-24 14h-1.5a30.4 30.4 0 0 1-23.8-11.6l-57.3-72.9a20 20 0 1 1 31.4-24.7l48.5 61.6 65.7-113.8a20 20 0 0 1 34.7 20z"
		/>
		<path
			fill="#0a4b6c"
			d="M409 244.7c1.7-10.5 18.5-20 18.5-31s-16.8-20.4-18.4-30.9c-1.7-10.8 11.3-25 8-35.1-3.4-10.3-22.2-14.2-27.1-23.7-5-9.7 3-27.2-3.3-35.9-6.4-8.7-25.5-6.6-33.1-14.2-7.6-7.6-5.5-26.7-14.2-33-2.8-2-6.4-2.7-10.4-2.7-5.3 0-11.1 1-16.4 1a20 20 0 0 1-9-1.7c-9.6-4.9-13.5-23.7-23.8-27a11.7 11.7 0 0 0-3.6-.6c-9 0-20.3 8.7-29.5 8.7a13.6 13.6 0 0 1-2-.2c-10.5-1.6-20-18.4-31-18.4s-20.4 16.8-30.9 18.4a13.6 13.6 0 0 1-2 .2c-9.2 0-20.5-8.7-29.5-8.7a11.4 11.4 0 0 0-3.6.5c-10.3 3.4-14.2 22.2-23.7 27.1a19.9 19.9 0 0 1-9 1.7c-5.4 0-11.2-1-16.5-1-4 0-7.6.6-10.4 2.6-8.7 6.4-6.6 25.5-14.2 33.1-7.6 7.6-26.7 5.5-33 14.2-6.4 8.7 1.5 26.2-3.4 35.9-4.9 9.5-23.7 13.4-27 23.7-3.4 10.1 9.6 24.3 8 35.1-1.7 10.5-18.5 20-18.5 31s16.8 20.4 18.4 31c1.7 10.7-11.3 24.9-8 35 3.4 10.3 22.2 14.2 27.1 23.7 5 9.7-3 27.2 3.3 36 6.4 8.6 25.5 6.5 33.1 14a22 22 0 0 1 4.9 9.6c2.4 8.6 3.3 19.2 9.3 23.6 2.8 2 6.4 2.6 10.4 2.6 5.3 0 11.1-1 16.4-1a20 20 0 0 1 9 1.7c9.6 4.9 13.5 23.8 23.8 27.1a11.7 11.7 0 0 0 3.6.5c9 0 20.3-8.6 29.5-8.6a13.6 13.6 0 0 1 2 .1c8 1.3 15.6 11.5 23.6 16.1a13 13 0 0 0 14.7 0c8-4.6 15.5-14.8 23.6-16a13.6 13.6 0 0 1 2-.2c9.2 0 20.5 8.6 29.5 8.6a11.4 11.4 0 0 0 3.6-.5c10.3-3.3 14.2-22.2 23.7-27a19.9 19.9 0 0 1 9-1.8c5.4 0 11.2 1 16.5 1 4 0 7.7-.6 10.4-2.6 6-4.4 6.9-15 9.4-23.6 1-3.8 2.5-7.2 4.8-9.5 7.6-7.6 26.8-5.5 33.1-14.2 6.3-8.7-1.6-26.2 3.3-35.9 4.9-9.5 23.8-13.4 27.1-23.7 3.3-10.1-9.7-24.3-8-35zm-38.3 35.4a169.6 169.6 0 0 1-31.7 49q-2.3 2.6-4.8 5a169.8 169.8 0 0 1-116 49.9h-8.9a169.8 169.8 0 0 1-116-49.8l-4.8-5A170.3 170.3 0 1 1 370.7 280z"
		/>
	</svg>
)

const WhoPage = ({
	setDropletState,
	scrollbarWidth,
	ourFormData,
	setOurFormData,
	animateTransitions,
}) => {
	// const [answerVal, setAnswerVal] = useState()
	// const [continued, setContinued] = useState()

	const answerVal = dlv(ourFormData, 'who.answer')

	const continued = dlv(ourFormData, 'who.continued')
	const setContinued = state => {
		// clone our form data
		let newFormData = JSON.parse(JSON.stringify(ourFormData))
		// update w/ answer
		dset(newFormData, 'who.continued', state)
		setOurFormData(newFormData)
	}

	useEffect(() => {
		// update droplets
		if (!continued) {
			setDropletState([1, 1, 1, 1, 0])
		} else {
			setDropletState([1, 1, 1, 1, 0.5])
		}
	}, [continued])

	const initialViewTransition = useTransition(!answerVal, null, {
		initial: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const secondViewTransition = useTransition(answerVal && !continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const thirdViewTransition = useTransition(continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
	})

	return (
		<div className={`page page-who`}>
			{initialViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div key={key} style={props} className="page-sections">
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													Who are licensed{' '}
													<strong>fire sprinkler contractors?</strong>
												</h2>
											</div>,
											<div className="gradient-wrap">
												<div className="gw-inner">
													<WhoForm
														answerVal={answerVal}
														ourFormData={ourFormData}
														setOurFormData={setOurFormData}
													/>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>
											Receive more information on installing fire sprinklers...
										</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{secondViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="page-sections view-two"
						>
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													Who are licensed{' '}
													<strong>fire sprinkler contractors?</strong>
												</h2>
											</div>,
											<div className="gradient-wrap">
												<div className="gw-inner second">
													{answerVal === 'Single-Family Home' ? (
														<React.Fragment>
															<h3>Congratulations!</h3>
															<div className="answer-exp">
																<p>
																	You have options for installing sprinklers,
																	whether it's as a retrofit in your existing
																	home or during new construction.
																</p>
															</div>
															<p>
																Learn why you need a qualified sprinkler
																contractor to do an installation:
															</p>
														</React.Fragment>
													) : (
														<React.Fragment>
															<h3>What Next?</h3>
															<div className="answer-exp">
																<p>
																	Unfortunately, the decision to install
																	sprinklers is up to your building owner or
																	condo association, but you can advocate for
																	fire sprinklers. More and more local
																	governments are mandating retrofits of
																	sprinklers in multi-family dwellings.
																</p>
															</div>
															<p>
																Learn why a qualified installer is needed, then
																talk to your landlord about fire sprinklers. At
																the end, we’ll provide you with free tools and
																guidance to make your case:
															</p>
														</React.Fragment>
													)}
													<Button
														className="btn btn-white"
														onClick={() => setContinued(true)}
													>
														Continue ››
													</Button>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>
											Receive more information on installing fire sprinklers...
										</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{thirdViewTransition.map(
				({ item, key, props }) =>
					item && (
						<React.Fragment key={key}>
							<animated.div
								style={props}
								className={`page-sections view-three`}
							>
								<div
									className="main ps-main"
									style={{
										backgroundImage: `url(${rainImg})`,
										overflow: 'hidden',
									}}
								>
									<div className="background-video">
										{/* <VideoCover
											videoOptions={{
												src:
													'http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4',
												autoPlay: true,
												muted: true
											}}
										/> */}
									</div>
									<div className="inner-wrap">
										<TrailEls
											animateTransitions={animateTransitions}
											items={[
												<div className="section-header">
													<h2 className="section-heading">
														Who are licensed{' '}
														<strong>fire sprinkler contractors?</strong>
													</h2>
												</div>,
												<div className="award-svg svg-wrap">
													<AwardIcon />
												</div>,
												<div className="view-three-content">
													<h2>Licensed Fire Sprinkler Contractors</h2>
													<h3>{`Qualified \u00b7 Experienced`}</h3>
													<p>
														Licensed fire sprinkler contractors employ
														professional engineers and certified technicians
														with training and experience in the design and
														layout of fire sprinkler systems. Any contractor
														should not only be licensed but bonded, which
														ensures that both they and you are protected by
														insurance. Hiring expert sprinkler installers means
														you get what you pay for: careful installation, a
														good-looking system, and peace of mind.
													</p>
													<ButtonA href="#learn_more" className="btn">
														Learn More
													</ButtonA>
												</div>,
											]}
										/>
									</div>
								</div>
								<div id="learn_more">
									<WhoLongContent />
								</div>
							</animated.div>
							<Link
								to="/upkeep"
								className="next-link"
								style={{ right: scrollbarWidth }}
							>
								Next
							</Link>
						</React.Fragment>
					)
			)}
		</div>
	)
}

export default WhoPage
