import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { ButtonA, Button } from '../components/button'
import dlv from 'dlv'
import dset from 'dset'
import { TrailEls } from '../helpers'
import loadjs from 'loadjs'
// import VideoCover from 'react-video-cover'

// import rainImg from '../images/testrain.png'

import { WhatForm } from '../forms.js'

import WhatLongContent from './what-long-content'

const smokeImage =
	'https://res.cloudinary.com/boostcreative/image/upload/v1556802904/smoke_qb1sli.png'

const PiggyBankIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 666 698">
		<path
			fill="#0a4b6c"
			d="M661 456c-4-1-6 2-7 4l-3 3-5 4a21 21 0 0 1-9 3l3-6c1-3 2-15-3-23-7-11-17-15-22-14-4 0-17 1-24 11-7 12-6 28-1 35l4 4c-4 2-4 1-9 1-9 0-12-9-13-17l1-15c0-123-115-222-256-222a280 280 0 0 0-156 46 13 13 0 0 1-13 1l-56-27a6 6 0 0 0-9 7l21 63a13 13 0 0 1-2 12 204 204 0 0 0-37 86 13 13 0 0 1-11 11l-43 8a13 13 0 0 0-11 15l12 66a13 13 0 0 0 13 10h47a13 13 0 0 1 11 7c19 34 50 60 89 78a32 32 0 0 1 18 19l19 63a13 13 0 0 0 12 9h51a13 13 0 0 0 13-12l4-39a13 13 0 0 1 13-12h35a13 13 0 0 1 13 11l4 40a13 13 0 0 0 13 12h51a13 13 0 0 0 12-9l20-66a31 31 0 0 1 17-19c43-20 75-51 92-91 16-3 39-2 58-17l8-7c8-1 15-2 21-5 7-4 10-6 12-9a73 73 0 0 0 7-9c1-2 2-8-4-10zm-543-46a16 16 0 1 1 16-16 16 16 0 0 1-16 16zm289-131l-5 11a9 9 0 0 1-11 5 226 226 0 0 0-149 0 9 9 0 0 1-11-5l-5-11a9 9 0 0 1 5-12h1a264 264 0 0 1 85-15 255 255 0 0 1 85 15 9 9 0 0 1 5 12zm201 167c3-2 8-2 10 1 4 5-1 14-2 15a21 21 0 0 1-4 6 20 20 0 0 1-6-8c-2-5-2-11 2-14z"
		/>
		<path
			fill="#ff4242"
			d="M350 6a100 100 0 0 0-127 127 95 95 0 0 0 60 61A100 100 0 0 0 411 66a95 95 0 0 0-61-60zm9 142a50 50 0 0 1-27 13 5 5 0 0 0-3 5v7a5 5 0 0 1-5 5h-16a4 4 0 0 1-4-5v-8a4 4 0 0 0-4-4 78 78 0 0 1-31-14 5 5 0 0 1-1-7l11-12a4 4 0 0 1 6 0c17 12 47 13 56 3a9 9 0 0 0 3-7 13 13 0 0 0-4-8c-4-4-11-4-22-3-12 0-28 1-39-10-12-13-11-34-3-46 6-8 14-14 24-17a5 5 0 0 0 4-4V26a4 4 0 0 1 4-4h16a5 5 0 0 1 5 4v9a5 5 0 0 0 3 5 90 90 0 0 1 22 8 4 4 0 0 1 2 7l-8 13a5 5 0 0 1-6 2c-21-11-39-8-45 1-3 4-3 11 0 14s10 3 20 3c12-1 28-2 40 10a38 38 0 0 1 12 26 34 34 0 0 1-10 24z"
		/>
	</svg>
)

/////////// START SMOKE ////////////
function createSmoke() {
	let camera,
		scene,
		renderer,
		geometry,
		material,
		mesh,
		clock,
		cubeSineDriver,
		light,
		smokeTexture,
		smokeMaterial,
		smokeGeo,
		smokeParticles,
		delta
	let { THREE } = window

	init()
	animate()

	function init() {
		clock = new THREE.Clock()

		renderer = new THREE.WebGLRenderer({ alpha: true })
		renderer.setSize(window.innerWidth, window.innerHeight)

		scene = window.renderSceneWhat = new THREE.Scene()

		camera = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			1,
			10000
		)
		camera.position.z = 1000
		scene.add(camera)

		geometry = new THREE.BoxGeometry(200, 200, 200)
		material = new THREE.MeshLambertMaterial({
			color: 0xaa6666,
			wireframe: false,
		})
		mesh = new THREE.Mesh(geometry, material)
		//scene.add( mesh );
		cubeSineDriver = 0

		THREE.ImageUtils.crossOrigin = '' //Need this to pull in crossdomain images from AWS

		light = new THREE.DirectionalLight(0xffffff, 0.5)
		light.position.set(-1, 0, 1)
		scene.add(light)

		// smokeTexture = THREE.ImageUtils.loadTexture(
		// 	'https://res.cloudinary.com/boostcreative/image/upload/v1556802904/smoke_qb1sli.png'
		smokeTexture = new THREE.TextureLoader().load(smokeImage)
		smokeMaterial = new THREE.MeshLambertMaterial({
			color: 0xffffff,
			map: smokeTexture,
			transparent: true,
		})
		smokeGeo = new THREE.PlaneBufferGeometry(330, 330)
		smokeParticles = []

		for (let p = 0; p < 50; p++) {
			var particle = new THREE.Mesh(smokeGeo, smokeMaterial)
			particle.position.set(
				Math.random() * 600 - 250,
				Math.random() * 500 - 250,
				Math.random() * 1000 - 100
			)
			particle.rotation.z = Math.random() * 360
			scene.add(particle)
			smokeParticles.push(particle)
		}

		let container = document.getElementById('what_view_three')
		if (!!container) {
			container.querySelector('.ps-main').appendChild(renderer.domElement)
		} else {
			destroySmoke()
		}
	}

	function animate() {
		// note: three.js includes requestAnimationFrame shim
		delta = clock.getDelta()
		requestAnimationFrame(animate)
		evolveSmoke()
		render()
	}

	function evolveSmoke() {
		var sp = smokeParticles.length
		while (sp--) {
			smokeParticles[sp].rotation.z += delta * 0.2
		}
	}

	function render() {
		mesh.rotation.x += 0.005
		mesh.rotation.y += 0.01
		cubeSineDriver += 0.01
		mesh.position.z = 100 + Math.sin(cubeSineDriver) * 500
		renderer.render(scene, camera)
	}
}

function destroySmoke() {
	let scene = window.renderSceneWhat
	if (scene) {
		for (let i = scene.children.length - 1; i >= 0; i--) {
			const object = scene.children[i]
			if (object.type === 'Mesh') {
				object.geometry.dispose()
				object.material.dispose()
				scene.remove(object)
			}
		}
	}
}
/////////// END SMOKE ////////////

const WhatPage = ({
	setDropletState,
	scrollbarWidth,
	ourFormData,
	setOurFormData,
	animateTransitions,
}) => {
	// const [answerVal, setAnswerVal] = useState()
	// const [continued, setContinued] = useState()

	const answerVal = dlv(ourFormData, 'what.answer')

	const [madeSmoke, setMadeSmoke] = useState()

	const continued = dlv(ourFormData, 'what.continued')
	const setContinued = state => {
		// clone our form data
		let newFormData = JSON.parse(JSON.stringify(ourFormData))
		// update w/ answer
		dset(newFormData, 'what.continued', state)
		setOurFormData(newFormData)
	}

	useEffect(() => {
		// update droplets
		if (!continued) {
			setDropletState([1, 1, 1, 0, 0])
		} else {
			setDropletState([1, 1, 1, 0.5, 0])
		}
		// add smoke
		if (continued && !madeSmoke) {
			setMadeSmoke(true)
			setTimeout(() => {
				loadjs(
					[
						'https://cdn.jsdelivr.net/npm/three@0.103.0/build/three.min.js',
						smokeImage,
					],
					createSmoke
				)
			}, 800)
		}
		return () => {
			destroySmoke()
		}
	}, [continued])

	const initialViewTransition = useTransition(!answerVal, null, {
		initial: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const secondViewTransition = useTransition(answerVal && !continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const thirdViewTransition = useTransition(continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
	})

	return (
		<div className={`page page-what`}>
			{initialViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div key={key} style={props} className="page-sections">
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													What should you consider{' '}
													<strong>when installing fire sprinklers?</strong>
												</h2>
											</div>,
											<div className="gradient-wrap">
												<div className="gw-inner">
													<WhatForm
														answerVal={answerVal}
														ourFormData={ourFormData}
														setOurFormData={setOurFormData}
													/>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>
											Receive more information on installing fire sprinklers...
										</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{secondViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="page-sections view-two"
						>
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													What should you consider{' '}
													<strong>when installing fire sprinklers?</strong>
												</h2>
											</div>,
											<div className="gradient-wrap">
												<div className="gw-inner second">
													{answerVal === 'No' ? (
														<React.Fragment>
															<h3>Excellent!</h3>
															<div className="answer-exp">
																<p>
																	With a qualified installer, you can retrofit
																	your home with fire sprinklers - if you have
																	the authority to make renovations.
																</p>
															</div>
															<p>Learn what you need to know:</p>
														</React.Fragment>
													) : (
														<React.Fragment>
															<h3>Excellent!</h3>
															<div className="answer-exp">
																<p>
																	New homes are the best time to install fire
																	sprinklers. On average, they cost less than
																	the expense of a few gallons of gas every
																	month over the life of a mortgage.
																</p>
															</div>
															<p>Learn the numbers:</p>
														</React.Fragment>
													)}
													<Button
														className="btn btn-white"
														onClick={() => setContinued(true)}
													>
														Continue ››
													</Button>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>
											Receive more information on installing fire sprinklers...
										</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{thirdViewTransition.map(
				({ item, key, props }) =>
					item && (
						<React.Fragment key={key}>
							<animated.div
								style={props}
								className={`page-sections view-three`}
								id="what_view_three"
							>
								<div
									className="main ps-main"
									style={{
										overflow: 'hidden',
									}}
								>
									<div className="background-video">
										{/* <VideoCover
											videoOptions={{
												src:
													'http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4',
												autoPlay: true,
												muted: true
											}}
										/> */}
									</div>
									<div className="inner-wrap">
										<TrailEls
											animateTransitions={animateTransitions}
											items={[
												<div className="section-header">
													<h2 className="section-heading">
														What should you consider{' '}
														<strong>when installing fire sprinklers?</strong>
													</h2>
												</div>,
												<div className="svg-wrap">
													<PiggyBankIcon />
												</div>,
												<div className="view-three-content">
													<h2>Sprinklers Cost Less Than You Think.</h2>
													<h3>Especially when you own a single-family home.</h3>
													<p>
														Any building can get sprinklers. However, whether
														it’s new construction or retrofitting an existing
														home will dictate the system you choose based on
														local laws and water supply. Fire sprinklers in new
														construction tend to only add{' '}
														<strong>
															1-2% to the total price – that's only $10-$20 per
															month over the life of an average mortgage.
														</strong>
													</p>
													<ButtonA href="#learn_more" className="btn">
														Learn More
													</ButtonA>
												</div>,
											]}
										/>
									</div>
								</div>
								<div id="learn_more">
									<WhatLongContent />
								</div>
							</animated.div>
							<Link
								to="/who"
								className="next-link"
								style={{ right: scrollbarWidth }}
							>
								Next
							</Link>
						</React.Fragment>
					)
			)}
		</div>
	)
}

export default WhatPage
