import React, { useEffect } from 'react'

import { ButtonLink } from '../components/button'

import rainBackgroundWhite from '../images/background-white-rain.jpg'
import rainBackgroundBlue from '../images/background-blue-rain.jpg'
// import chartImage from '../images/chart-fire-causes.png'
// import graphicHouse from '../images/graphic-house.png'

const HowLongContent = ({ setDropletState }) => {
	useEffect(() => {
		setDropletState && setDropletState([0, 0, 0, 0, 0])
	}, [])
	return (
		<div
			className="default-page about-long-content"
			style={{ backgroundImage: `url(${rainBackgroundWhite})` }}
		>
			<section className="def-lead">
				<div className="main">
					<div className="inner-wrap">
						<div className="section-header">
							<h2 className="section-heading">
								How do <strong>fire sprinklers work?</strong>
							</h2>
							<p>
								Fire sprinklers react to the local ambient temperature to
								provide life-saving, property-protecting water, <i>fast</i>. And
								about 90% of the time, a single fire sprinkler is all that’s
								needed to control a
								<span className="p-cite">
									{' '}
									fire.
									<span className="the-citations">
										<sup>1</sup>
									</span>
								</span>
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float">
				<div className="main">
					<div className="inner-wrap">
						<div className="vf-vid">
							<div className="responsive-embed">
								<iframe
									title="Buy Time, Buy Life"
									loading="lazy"
									src="https://www.youtube.com/embed/bs60ssf9DWU?rel=0"
									frameBorder="0"
									allowFullScreen
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
						<div className="vf-content">
							<p>
								Unlike what you see in movies or on TV, fire sprinklers are not
								set off by smoke or a fire alarm. They do not deploy all at
								once. Instead, they work via a very simple, effective mechanical
								principle that has remained essentially unchanged since the
								first automatic fire sprinklers were installed in 1874.
							</p>
							<p>
								Home sprinkler systems have a network of water-filled piping,
								similar to and sometimes the same as the plumbing system, with
								ceiling or wall-mounted sprinklers attached to the pipe at
								intervals; usually only 1 or 2 fire sprinklers per room. Each
								sprinkler is constructed with a brass frame and a plug that is
								held in place by a heat-sensitive element – either a glass bulb
								filled with liquid or a fusible metal link.
							</p>
							<p>
								When a fire starts and the heat around a sprinkler head reaches
								a certain temperature – usually between 135 ° F and 225° F, with
								155° F being the most common – the glass bulb breaks or the
								metal link melts. The plug then falls away and releases water
								which sprays across the room when it hits the deflector, a
								fan-shaped piece of metal at the bottom of the head.
							</p>
							<p>That's it.</p>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float vf-left">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							<strong>It’s a myth</strong> that sprinklers cause a lot of water
							damage to your home
						</h2>
						<div className="vf-vid">
							<div className="responsive-embed">
								<iframe
									title="Buy Time, Buy Life"
									loading="lazy"
									src="https://www.youtube.com/embed/TAx3kD1Fqgc?rel=0"
									frameBorder="0"
									allowFullScreen
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
						<div className="vf-content">
							<p>
								Since sprinklers are individually heat-activated, only the
								sprinkler or sprinklers close to the fire will actually spray
								water. And since only one sprinkler is usually all it takes to
								control the fire, the water damage is limited to that room while
								stopping the fire from spreading. This prevents both more fire
								damage and the destruction caused by firefighters using a far
								greater amount of water.
							</p>
							<p>
								Residential sprinkler systems are designed to flow water to a
								maximum of two deployed sprinklers – about 26 gallons per
								minute. In contrast, a fire department’s attack line hoses spray
								over 150 gallons per minute and the total water they wind up
								using to fight a fire is{' '}
								<i>10 times or more the amount used by</i>
								<span className="p-cite">
									{' '}
									<i>sprinklers.</i>
									<span className="the-citations">
										<sup>2</sup>
									</span>
								</span>
							</p>
							<p>
								It’s pretty obvious which option will cause more water damage …
								never mind the vast fire damage that sprinklers prevent.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							Concealed sprinklers deploy a little differently –{' '}
							<strong>while looking great in your home</strong>
						</h2>
						<div className="vf-vid">
							<div className="responsive-embed">
								<iframe
									title="Buy Time, Buy Life"
									loading="lazy"
									src="https://www.youtube.com/embed/1BN9DD6wnms?rel=0"
									frameBorder="0"
									allowFullScreen
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
						<div className="vf-content">
							<p>
								Fire sprinklers are available in a variety of styles including
								hanging pendents, uprights, and sidewalls. One of the most
								popular types for homeowners, however, are concealed fire
								sprinklers. As the name implies, these are an attractive
								alternative that hides the sprinkler head behind a decorative
								plate sitting flush with the ceiling or wall.
							</p>
							<p>
								The cover plate is held in place by a metal link that melts at
								temperatures about 20 degrees lower than the temperature at
								which the sprinkler head activates. When the temperature gets
								high enough, the plate drops away and exposes the sprinkler,
								allowing it to activate and spray water.
							</p>
							<p>
								Cover plates come in common ceiling colors such as white or you
								can choose custom styles that can be matched to various paint
								and finish schemes. Concealed sprinklers are reliable and pretty
								– hardly anyone will have any idea your home is protected,
								unless they know what to look for.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section
				className="blue-two-col"
				style={{ backgroundImage: `url(${rainBackgroundBlue})` }}
			>
				<div className="main">
					<div className="inner-wrap">
						<h2>
							Quick response residential sprinklers are designed to{' '}
							<strong>save lives</strong>
						</h2>
						<div className="two-col-content">
							<div>
								<p>
									Residential sprinklers are quick response sprinklers: this
									means they activate faster than "standard response" sprinklers
									and discharge water higher on the walls to keep fire from
									spreading. Their main goal is to{' '}
									<strong>save lives by buying time</strong>. While residential
									sprinklers often extinguisher a fire, their primary purpose is
									to keep the fire from spreading by reducing its heat and
									soaking its fuel sources – the walls and furnishings – giving
									the fire department a chance to arrive and enabling you and
									your family to escape.
								</p>
							</div>
							<div>
								<p>
									Simply put: they work. Accidental sprinkler discharges due to
									manufacturing defects are incredibly rare –{' '}
									<strong>about 1 in 16 million sprinklers</strong> – and deaths
									in sprinklered homes{' '}
									<strong>are 81% lower than in homes without </strong>
									<span className="p-cite">
										{' '}
										<strong>
											them.
											<span className="the-citations">
												<sup>3</sup>
												<sup>4</sup>
											</span>
										</strong>
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="narrow-jumble">
				<div className="main">
					<div className="inner-wrap">
						<h3>Fire sprinklers are simple, reliable, and effective.</h3>
						<ButtonLink to="/what" className="btn">
							Learn where sprinklers can be installed and how much they cost ››
						</ButtonLink>
					</div>
				</div>
			</section>

			<section className="citations">
				<div className="main">
					<div className="inner-wrap">
						<h2>Sources:</h2>
						<ol>
							<li>
								"How home fire sprinklers work." The National Fire Protection
								Association Fire Sprinkler Initiative.{' '}
							</li>
							<li>
								<i>
									NFPA 13D: Standard for the Installation of Sprinkler Systems
									in One- and Two-Family Dwellings and Manufactured Homes.
								</i>{' '}
								National Fire Protection Association. 2019.
							</li>
							<li>
								"Fire Sprinklers: Home Builder’s Guide to Construction in
								Wildfire Zones." Federal Emergency Management Agency (FEMA).
							</li>
							<li>
								Ahrens, Marty. "Home Structure Fires." National Fire Protection
								Association.
							</li>
						</ol>
					</div>
				</div>
			</section>
		</div>
	)
}

export default HowLongContent
