import React, { useEffect, useState } from 'react'
import homeImage from '../images/home.jpg'
import ytImage from '../images/yt-placeholder.jpeg'
import { useSpring, useTransition, animated } from 'react-spring'
import { Link } from 'react-router-dom'
import { TrailEls } from '../helpers'

const YoutubeIframe = () => (
	<iframe
		title="Buy Time, Buy Life"
		loading="lazy"
		src="https://www.youtube.com/embed/videoseries?list=PLUXbTJdgq3EGqwJodcpryPqaZa-ckRc4U"
		// src="https://www.youtube.com/embed/eV-qHNl_-6U?playsinline=1&autoplay=1&rel=0"
		frameBorder="0"
		allowFullScreen
		autoPlay
		allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
	/>
)

const NextButton = () => {
	return (
		<Link className="home-next" to="/why">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 512 512"
				width="70"
				fill="#fff"
			>
				<path d="M508.9 248.5l-160-160a10.7 10.7 0 1 0-15.1 15l141.8 141.8h-465a10.7 10.7 0 1 0 0 21.4h465L333.8 408.5a10.7 10.7 0 1 0 15 15l160-160c4.2-4.1 4.2-10.9 0-15z" />
			</svg>
			<h3>
				Learn how to protect your family with <br />
				fire sprinklers
			</h3>
		</Link>
	)
}

const VideoOverlay = ({ playVideo }) => {
	const [showOverlay, setShowOverlay] = useState(true)
	const [hovering, setHover] = useState()
	const iconStyle = useSpring({
		transform: showOverlay
			? hovering
				? 'scale(1.08)'
				: 'scale(1)'
			: 'scale(1)',
		// config: config.stiff
	})
	const transitions = useTransition(showOverlay, null, {
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})
	const startPlaying = () => {
		playVideo(true)
		setShowOverlay(false)
	}
	return transitions.map(
		({ item, key, props }) =>
			item && (
				<animated.button
					key={key}
					style={props}
					className="vid-overlay"
					onClick={startPlaying}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					onFocus={() => setHover(true)}
					onBlur={() => setHover(false)}
				>
					<div className="vo-inner">
						<animated.div style={iconStyle} className="vo-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="40">
								<path fill="#FFF" fillRule="evenodd" d="M0 .3L29.7 20 0 39.7" />
							</svg>
						</animated.div>
						<p>Click to play</p>
					</div>
				</animated.button>
			)
	)
}

const HomePage = props => {
	let { setDropletState, animateTransitions } = props

	const [videoPlaying, playVideo] = useState()

	useEffect(() => {
		setDropletState([0, 0, 0, 0, 0])
	}, [])

	return (
		<div
			className="page page-home"
			style={{ backgroundImage: `url(${homeImage})` }}
		>
			<div className="main">
				<div className="inner-wrap">
					<TrailEls
						animateTransitions={animateTransitions}
						items={[
							<h2>
								Fire Sprinklers Buy Time. <strong>Time Buys Life.</strong>
							</h2>,
							<div className="home-embed">
								<div
									className="responsive-embed"
									style={{ backgroundImage: `url(${ytImage})` }}
									// onClick={() => playVideo(true)}
								>
									{videoPlaying && <YoutubeIframe />}
									<VideoOverlay playVideo={playVideo} />
								</div>
							</div>,
						]}
					/>
				</div>
			</div>
			<NextButton />
		</div>
	)
}

export default HomePage
