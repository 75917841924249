import React from 'react'
import logo from './images/logo.png'
// import { NavLink } from '@reach/router'
import { NavLink } from 'react-router-dom'

const WaterDroplets = ({ dropletState }) => {
	return dropletState.map((fillAmount, i) => {
		let fill = 'transparent'
		if (fillAmount === 1) {
			fill = '#fff'
		} else if (fillAmount === 0.5) {
			fill = 'url(#myGradient)'
		}
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="19px"
				height="28px"
				key={i}
			>
				<defs>
					<linearGradient id="myGradient" gradientTransform="rotate(90)">
						<stop offset="60%" stopColor="rgba(255, 255, 255, 0)" />
						<stop offset="40%" stopColor="#fff" />
					</linearGradient>
				</defs>
				<path
					fill={fill}
					strokeWidth="1px"
					stroke="#fff"
					d="M9.500,0.402 C12.003,6.858 13.858,9.230 17.033,15.538 C20.114,21.660 15.548,27.812 9.500,27.812 C3.452,27.812 -1.115,21.660 1.967,15.538 C5.141,9.230 6.886,6.858 9.500,0.402 Z"
				/>
			</svg>
		)
	})
}

const Header = ({
	dropletState,
	showMobileMenu,
	setShowMobileMenu,
	windowWidth,
}) => {
	return windowWidth > 1259 ? (
		<div className="header-wrap">
			<div className="corner-logo">
				<img src={logo} alt="" />
			</div>
			<nav className="navbar">
				<div className="nav-left">
					<ul>
						<li>
							<NavLink exact to="/">
								Home
							</NavLink>
						</li>
						<li>
							<NavLink to="/about">About NFSA</NavLink>
						</li>
					</ul>
				</div>
				<div className="nav-center">
					<ul>
						<li>
							<NavLink to="/why">Why</NavLink>
						</li>
						<li>
							<NavLink to="/how">How</NavLink>
						</li>
						<li>
							<NavLink to="/what">What</NavLink>
						</li>
						<li>
							<NavLink to="/who">Who</NavLink>
						</li>
						<li>
							<NavLink to="/upkeep">Upkeep</NavLink>
						</li>
					</ul>
				</div>
				<div className="nav-right droplets">
					<WaterDroplets dropletState={dropletState || [(0, 0, 0, 0, 0)]} />
				</div>
			</nav>
		</div>
	) : (
		// mobile
		<div className="header-wrap-mobile">
			<div className="hwm-logo">
				<img src={logo} alt="" />
			</div>
			<div className="droplets">
				<WaterDroplets dropletState={dropletState || [(0, 0, 0, 0, 0)]} />
			</div>
			<button
				id="toggle_nav"
				className="toggle-nav"
				onClick={() => setShowMobileMenu(!showMobileMenu)}
			>
				<i className={`hamburger${showMobileMenu ? ' active' : ''}`}>
					<div />
					<div />
					<div />
				</i>
				<span className="sr-only">Toggle mobile menu</span>
			</button>
		</div>
	)
}

export default Header
