import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { ButtonA, Button } from '../components/button'
import dlv from 'dlv'
import dset from 'dset'
import { TrailEls } from '../helpers'
import loadjs from 'loadjs'
// import VideoCover from 'react-video-cover'

// import rainImg from '../images/testrain.png'

import { WhenForm } from '../forms.js'

import WhenLong from './when-long-content'

const smokeImage =
	'https://res.cloudinary.com/boostcreative/image/upload/v1556802904/smoke_qb1sli.png'

const ToolsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481 498">
		<path
			fill="#0a4b6c"
			d="M441 344a54 54 0 0 0-24-9s-41-9-96-63L218 169s-70-69-71-102l-2-8c-8-31-40-49-58-56-14-6-16-1-16 3l1 6 21 38c10 18-41 47-52 28L20 40l-4-4c-3-2-8-2-11 12-4 23-6 69 31 91a65 65 0 0 0 22 8s25 2 78 55l142 142s38 35 40 80l2 9c9 35 45 55 65 63 15 6 18 0 18-4l-1-6-24-43c-11-20 46-52 58-31l23 42 5 5c3 2 10 2 13-14 4-25 6-76-36-101zm-91-17c8 9 8 22 0 30l-1 1c-9 9-22 9-30 0L129 169c-8-8-8-22 0-30l2-1a21 21 0 0 1 29 0z"
		/>
		<path
			fill="#ff4242"
			d="M173 260l-5 5c-36 37-66 63-83 63a85 85 0 1 0 76 124c4-6 8-16 9-23 12-63 42-89 42-89l21-20zm-20 158l-7 7c-2 2-2 5-2 7l3 10c1 4-2 8-6 9l-9 3c-3 0-5 3-6 5l-2 9c-1 5-5 7-9 6l-10-3-7 2-7 7a8 8 0 0 1-11 0l-7-7-7-2-9 3c-5 1-9-1-10-6l-2-9c-1-2-3-5-6-5l-9-3c-4-1-7-5-6-9l3-9a8 8 0 0 0-2-8l-7-7a8 8 0 0 1 0-11l7-6c2-3 3-5 2-8l-3-9c-1-4 2-9 6-10l9-2c3-1 5-3 5-6l3-9a7 7 0 0 1 10-5l9 2c3 1 5 0 7-2l7-7a8 8 0 0 1 11 0l7 7c2 2 5 3 7 2l9-2a7 7 0 0 1 10 5l2 9a9 9 0 0 0 6 6l9 2c4 1 7 6 6 10l-3 9c0 3 0 6 2 8l7 6a8 8 0 0 1 0 11zM479 51c-3-16-8-15-12-13l-4 4-22 40c-11 19-64-10-53-30l21-39 2-6c0-4-3-9-17-3-19 7-52 25-61 58-16 59-75 114-75 114l-6 6 59 59 7-6c63-63 105-82 105-82a63 63 0 0 0 23-8c39-23 37-71 33-94z"
		/>
	</svg>
)

/////////// START SMOKE ////////////
function createSmoke() {
	let camera,
		scene,
		renderer,
		geometry,
		material,
		mesh,
		clock,
		cubeSineDriver,
		light,
		smokeTexture,
		smokeMaterial,
		smokeGeo,
		smokeParticles,
		delta
	let { THREE } = window

	init()
	animate()

	function init() {
		clock = new THREE.Clock()

		renderer = new THREE.WebGLRenderer({ alpha: true })
		renderer.setSize(window.innerWidth, window.innerHeight)

		scene = window.renderSceneWhen = new THREE.Scene()

		camera = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			1,
			10000
		)
		camera.position.z = 1000
		scene.add(camera)

		geometry = new THREE.BoxGeometry(200, 200, 200)
		material = new THREE.MeshLambertMaterial({
			color: 0xaa6666,
			wireframe: false,
		})
		mesh = new THREE.Mesh(geometry, material)
		//scene.add( mesh );
		cubeSineDriver = 0

		THREE.ImageUtils.crossOrigin = '' //Need this to pull in crossdomain images from AWS

		light = new THREE.DirectionalLight(0xffffff, 0.5)
		light.position.set(-1, 0, 1)
		scene.add(light)

		// smokeTexture = THREE.ImageUtils.loadTexture(
		// 	'https://res.cloudinary.com/boostcreative/image/upload/v1556802904/smoke_qb1sli.png'
		smokeTexture = new THREE.TextureLoader().load(smokeImage)
		smokeMaterial = new THREE.MeshLambertMaterial({
			color: 0xffffff,
			map: smokeTexture,
			transparent: true,
		})
		smokeGeo = new THREE.PlaneBufferGeometry(330, 330)
		smokeParticles = []

		for (let p = 0; p < 50; p++) {
			var particle = new THREE.Mesh(smokeGeo, smokeMaterial)
			particle.position.set(
				Math.random() * 600 - 250,
				Math.random() * 500 - 250,
				Math.random() * 1000 - 100
			)
			particle.rotation.z = Math.random() * 360
			scene.add(particle)
			smokeParticles.push(particle)
		}

		let container = document.getElementById('when_view_three')
		if (!!container) {
			container.querySelector('.ps-main').appendChild(renderer.domElement)
		} else {
			destroySmoke()
		}
	}

	function animate() {
		// note: three.js includes requestAnimationFrame shim
		delta = clock.getDelta()
		requestAnimationFrame(animate)
		evolveSmoke()
		render()
	}

	function evolveSmoke() {
		var sp = smokeParticles.length
		while (sp--) {
			smokeParticles[sp].rotation.z += delta * 0.2
		}
	}

	function render() {
		mesh.rotation.x += 0.005
		mesh.rotation.y += 0.01
		cubeSineDriver += 0.01
		mesh.position.z = 100 + Math.sin(cubeSineDriver) * 500
		renderer.render(scene, camera)
	}
}

function destroySmoke() {
	let scene = window.renderSceneWhen
	if (scene) {
		for (let i = scene.children.length - 1; i >= 0; i--) {
			const object = scene.children[i]
			if (object.type === 'Mesh') {
				object.geometry.dispose()
				object.material.dispose()
				scene.remove(object)
			}
		}
	}
}
/////////// END SMOKE ////////////

const WhenPage = ({
	setDropletState,
	scrollbarWidth,
	ourFormData,
	setOurFormData,
	animateTransitions,
}) => {
	// const [answerVal, setAnswerVal] = useState()
	// const [continued, setContinued] = useState()

	const answerVal = dlv(ourFormData, 'when.answer')

	const [madeSmoke, setMadeSmoke] = useState()

	const continued = dlv(ourFormData, 'when.continued')
	const setContinued = state => {
		// clone our form data
		let newFormData = JSON.parse(JSON.stringify(ourFormData))
		// update w/ answer
		dset(newFormData, 'when.continued', state)
		setOurFormData(newFormData)
	}

	useEffect(() => {
		// update droplets
		if (!continued) {
			setDropletState([1, 1, 1, 1, 1])
		}
		// add smoke
		if (continued && !madeSmoke) {
			setMadeSmoke(true)
			setTimeout(() => {
				loadjs(
					[
						'https://cdn.jsdelivr.net/npm/three@0.103.0/build/three.min.js',
						smokeImage,
					],
					createSmoke
				)
			}, 800)
		}
		return () => {
			destroySmoke()
		}
	}, [continued])

	const initialViewTransition = useTransition(!answerVal, null, {
		initial: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const secondViewTransition = useTransition(answerVal && !continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const thirdViewTransition = useTransition(continued, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
	})

	return (
		<div className={`page page-when`}>
			{initialViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div key={key} style={props} className="page-sections">
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													How do you maintain{' '}
													<strong>residential fire sprinklers?</strong>
												</h2>
											</div>,
											<div className="gradient-wrap">
												<div className="gw-inner">
													<WhenForm
														answerVal={answerVal}
														ourFormData={ourFormData}
														setOurFormData={setOurFormData}
													/>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>
											Receive more information on installing fire sprinklers...
										</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{secondViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="page-sections view-two"
						>
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													How do you maintain{' '}
													<strong>residential fire sprinklers?</strong>
												</h2>
											</div>,

											<div className="gradient-wrap">
												<div className="gw-inner second">
													{answerVal === 'Well' ? (
														<React.Fragment>
															<h3>Great</h3>
															<div className="answer-exp">
																<p>
																	It’s easy to hook up a fire sprinkler system
																	to a well, and the pressure provided by the
																	well’s pump is often enough to run the system.
																	If it’s not, a larger pump can be put in.
																</p>
															</div>
															<p>
																Now learn how easy it is to maintain a home fire
																sprinkler system
															</p>
														</React.Fragment>
													) : (
														<React.Fragment>
															<h3>Congrats!</h3>
															<div className="answer-exp">
																<p>
																	City water supplies are easy to hook up and
																	typically have sufficient pressure to run a
																	home sprinkler system. If a supply doesn’t, a
																	pump or a gravity tank may be used to augment
																	pressure.
																</p>
															</div>
															<p>
																Now learn how easy it is to maintain a home fire
																sprinkler system
															</p>
														</React.Fragment>
													)}
													<Button
														className="btn btn-white"
														onClick={() => setContinued(true)}
													>
														Continue ››
													</Button>
												</div>
											</div>,
										]}
									/>
								</div>
							</div>
							<div className="main ps-footer">
								<div className="inner-wrap">
									<div className="section-footer">
										<p>
											Receive more information on installing fire sprinklers...
										</p>
										<Link to="/full-form" className="def-link">
											Skip to the contact form
										</Link>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}

			{thirdViewTransition.map(
				({ item, key, props }) =>
					item && (
						<React.Fragment key={key}>
							<animated.div
								style={props}
								className={`page-sections view-three`}
								id="when_view_three"
							>
								<div
									className="main ps-main"
									style={{
										// backgroundImage: `url(${rainImg})`,
										overflow: 'hidden',
									}}
								>
									<div className="background-video">
										{/* <VideoCover
											videoOptions={{
												src:
													'http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4',
												autoPlay: true,
												muted: true
											}}
										/> */}
									</div>
									<div className="inner-wrap">
										<TrailEls
											animateTransitions={animateTransitions}
											items={[
												<div className="section-header">
													<h2 className="section-heading">
														How do you maintain{' '}
														<strong>residential fire sprinklers?</strong>
													</h2>
												</div>,
												<div className="tools-svg svg-wrap">
													<ToolsIcon />
												</div>,
												<div className="view-three-content">
													<h2>Simple Steps For Decades of Use</h2>
													<p>
														Home sprinklers are designed to be easy to maintain.
														Don't block or hang anything from sprinklers.
														Protect pipes from freezing temperatures and make
														sure they are always supplied with water. The
														National Fire Protection Association also recommends
														that you look over the sprinklers monthly and do a
														waterflow test every year. Following these steps
														ensures your system will work for decades.
													</p>
													<ButtonA href="#learn_more" className="btn">
														Learn More
													</ButtonA>
												</div>,
											]}
										/>
									</div>
								</div>
								<div id="learn_more">
									<WhenLong />
								</div>
							</animated.div>
							<Link
								to="/full-form"
								className="next-link"
								style={{ right: scrollbarWidth }}
							>
								Next
							</Link>
						</React.Fragment>
					)
			)}
		</div>
	)
}

export default WhenPage
