import React from 'react'
import dset from 'dset'
import dlv from 'dlv'
import ReactGA from 'react-ga'

const setAnswerVal = (ourFormData, setOurFormData, property, answer) => {
	// clone our form data
	let newFormData = JSON.parse(JSON.stringify(ourFormData))
	// update w/ answer
	dset(newFormData, property, answer)
	setOurFormData(newFormData)
}

export const WhyForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setWhyVal = val => {
		setAnswerVal(ourFormData, setOurFormData, 'why.answer', val)
		ReactGA.event({
			category: 'Why',
			action: val,
		})
	}
	return (
		<React.Fragment>
			<h3>{dlv(ourFormData, 'why.question')}</h3>
			<div className="no-form">
				<div>
					<input
						tabIndex="-1"
						type="radio"
						id="why1"
						name="why-radio-group"
						checked={answerVal === 'Yes'}
						onChange={() => setWhyVal('Yes')}
					/>
					<label
						tabIndex="0"
						htmlFor="why1"
						onKeyPress={() => setWhyVal('Yes')}
					>
						Yes
					</label>
				</div>
				<div>
					<input
						tabIndex="-1"
						type="radio"
						id="why2"
						name="why-radio-group"
						checked={answerVal === 'No'}
						onChange={() => setWhyVal('No')}
					/>
					<label tabIndex="0" htmlFor="why2" onKeyPress={() => setWhyVal('No')}>
						No
					</label>
				</div>
			</div>
		</React.Fragment>
	)
}

export const HowForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setHowVal = val => {
		setAnswerVal(ourFormData, setOurFormData, 'how.answer', val)
		ReactGA.event({
			category: 'How',
			action: val,
		})
	}
	return (
		<React.Fragment>
			<h3>{dlv(ourFormData, 'how.question')}</h3>
			<div className="no-form nf-column">
				<div>
					<div>
						<input
							tabIndex="-1"
							type="radio"
							id="how1"
							name="how-radio-group"
							checked={answerVal === 'Children'}
							onChange={e => setHowVal('Children')}
						/>
						<label
							tabIndex="0"
							onKeyPress={() => setHowVal('Children')}
							htmlFor="how1"
						>
							Children
						</label>
					</div>
					<div>
						<input
							tabIndex="-1"
							type="radio"
							id="how2"
							name="how-radio-group"
							checked={answerVal === 'Adults over 65'}
							onChange={() => setHowVal('Adults over 65')}
						/>
						<label
							tabIndex="0"
							onKeyPress={() => setHowVal('Adults over 65')}
							htmlFor="how2"
						>
							Adults over 65
						</label>
					</div>
					<div>
						<input
							tabIndex="-1"
							type="radio"
							id="how3"
							name="how-radio-group"
							checked={answerVal === 'Pets'}
							onChange={() => setHowVal('Pets')}
						/>
						<label
							tabIndex="0"
							onKeyPress={() => setHowVal('Pets')}
							htmlFor="how3"
						>
							Pets
						</label>
					</div>
					<div>
						<input
							tabIndex="-1"
							type="radio"
							id="how4"
							name="how-radio-group"
							checked={answerVal === 'Disabled Individuals'}
							onChange={() => setHowVal('Disabled Individuals')}
						/>
						<label
							tabIndex="0"
							onKeyPress={() => setHowVal('Disabled Individuals')}
							htmlFor="how4"
						>
							Disabled Individuals
						</label>
					</div>
					<div>
						<input
							tabIndex="-1"
							type="radio"
							id="how5"
							name="how-radio-group"
							checked={answerVal === 'None of the above'}
							onChange={() => setHowVal('None of the above')}
						/>
						<label
							tabIndex="0"
							onKeyPress={() => setHowVal('None of the above')}
							htmlFor="how5"
						>
							None of the above
						</label>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export const WhatForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setWhatVal = val => {
		setAnswerVal(ourFormData, setOurFormData, 'what.answer', val)
		ReactGA.event({
			category: 'What',
			action: val,
		})
	}
	const answers = [
		'No',
		'Yes, in 0-6 months',
		'Yes, in 6-12 months',
		'Yes, in 1+ years',
	]
	return (
		<React.Fragment>
			<h3>{dlv(ourFormData, 'what.question')}</h3>
			<div className="no-form nf-column">
				<div>
					{answers.map((answer, i) => (
						<div key={i}>
							<input
								tabIndex="-1"
								type="radio"
								id={`what${i}`}
								name="what-radio-group"
								checked={answerVal === answers[i]}
								onChange={e => setWhatVal(answers[i])}
							/>
							<label
								onKeyPress={e => setWhatVal(answers[i])}
								tabIndex="0"
								htmlFor={`what${i}`}
							>
								{answers[i]}
							</label>
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	)
}

export const WhoForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setWhoVal = val => {
		setAnswerVal(ourFormData, setOurFormData, 'who.answer', val)
		ReactGA.event({
			category: 'Who',
			action: val,
		})
	}
	const answers = ['Single-Family Home', 'Apartment', 'Condominium']
	return (
		<React.Fragment>
			<h3>{dlv(ourFormData, 'who.question')}</h3>
			<div className="no-form nf-column">
				<div>
					{answers.map((answer, i) => (
						<div key={i}>
							<input
								tabIndex="-1"
								type="radio"
								id={`who${i}`}
								name="who-radio-group"
								checked={answerVal === answers[i]}
								onChange={e => setWhoVal(answers[i])}
							/>
							<label
								onKeyPress={e => setWhoVal(answers[i])}
								tabIndex="0"
								htmlFor={`who${i}`}
							>
								{answers[i]}
							</label>
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	)
}

export const WhenForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setWhatVal = val => {
		setAnswerVal(ourFormData, setOurFormData, 'when.answer', val)
		ReactGA.event({
			category: 'Upkeep',
			action: val,
		})
	}
	const answers = ['Well', 'City water']
	return (
		<React.Fragment>
			<h3>{dlv(ourFormData, 'when.question')}</h3>
			<div className="no-form nf-column">
				<div>
					{answers.map((answer, i) => (
						<div key={i}>
							<input
								tabIndex="-1"
								type="radio"
								id={`when${i}`}
								name="when-radio-group"
								checked={answerVal === answers[i]}
								onChange={e => setWhatVal(answers[i])}
							/>
							<label
								onKeyPress={e => setWhatVal(answers[i])}
								tabIndex="0"
								htmlFor={`when${i}`}
							>
								{answers[i]}
							</label>
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	)
}

export const EmailForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setEmailVal = e => {
		setAnswerVal(ourFormData, setOurFormData, 'email', e.target.value)
	}
	return (
		<React.Fragment>
			<h3>Email address</h3>
			<div className="no-form nf-column">
				<label htmlFor="email" className="sr-only">
					Email
				</label>
				<input
					id="email"
					type="email"
					required
					value={answerVal || ''}
					onChange={setEmailVal}
				/>
			</div>
		</React.Fragment>
	)
}

export const NameForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setNameVal = e => {
		setAnswerVal(ourFormData, setOurFormData, 'name', e.target.value)
	}
	return (
		<React.Fragment>
			<h3>Your name</h3>
			<div className="no-form nf-column">
				<label htmlFor="name" className="sr-only">
					Name
				</label>
				<input
					id="name"
					type="text"
					required
					value={answerVal || ''}
					onChange={setNameVal}
				/>
			</div>
		</React.Fragment>
	)
}

export const ZipForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setNameVal = e => {
		setAnswerVal(ourFormData, setOurFormData, 'zip', e.target.value)
	}
	return (
		<React.Fragment>
			<h3>Zip code</h3>
			<div className="no-form nf-column">
				<label htmlFor="zip" className="sr-only">
					Zip Code
				</label>
				<input
					id="zip"
					type="text"
					required
					value={answerVal || ''}
					onChange={setNameVal}
				/>
			</div>
		</React.Fragment>
	)
}

export const PhoneForm = ({ answerVal, ourFormData, setOurFormData }) => {
	const setPhoneVal = e => {
		setAnswerVal(ourFormData, setOurFormData, 'phone', e.target.value)
	}
	return (
		<React.Fragment>
			<h3>Phone number</h3>
			<div className="no-form nf-column">
				<label htmlFor="phone" className="sr-only">
					Phone number
				</label>
				<input
					id="phone"
					required
					type="text"
					value={answerVal || ''}
					onChange={setPhoneVal}
				/>
			</div>
		</React.Fragment>
	)
}
