import React from 'react'
import { animated, useTrail } from 'react-spring'

const windowWidth = window.innerWidth

export const getScrollbarWidth = () => {
	var outer = document.createElement('div')
	outer.style.visibility = 'hidden'
	outer.style.width = '100px'
	outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps

	document.body.appendChild(outer)

	var widthNoScroll = outer.offsetWidth
	// force scrollbars
	outer.style.overflow = 'scroll'

	// add innerdiv
	var inner = document.createElement('div')
	inner.style.width = '100%'
	outer.appendChild(inner)

	var widthWithScroll = inner.offsetWidth

	// remove divs
	outer.parentNode.removeChild(outer)

	return widthNoScroll - widthWithScroll
}

export const TrailEls = ({ animateTransitions, items }) => {
	const trail = useTrail(items.length, {
		from: { opacity: 0, x: windowWidth > 700 ? 30 : 10 },
		to: { opacity: 1, x: 0 },
		delay: animateTransitions ? 170 : 60
	})
	return trail.map(({ x, height, ...rest }, index) => (
		<animated.div
			key={index}
			className="trails-text"
			style={{
				...rest,
				transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
			}}
		>
			<animated.div>{items[index]}</animated.div>
		</animated.div>
	))
}
