import 'react-app-polyfill/ie11'
import React, { useState, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './scss/app.scss'
// import { Router } from '@reach/router'
import { BrowserRouter, Switch, Route, __RouterContext } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import ReactGA from 'react-ga'
import * as serviceWorker from './serviceWorker'
import { getScrollbarWidth } from './helpers'

import Header from './header'
import MobileMenu from './mobileMenu'

import HomePage from './pages/home'
import WhyPage from './pages/why'
import HowPage from './pages/how'
import WhatPage from './pages/what'
import WhoPage from './pages/who'
import WhenPage from './pages/when'
import AboutPage from './pages/about'
import FullFormPage from './pages/full-form'

// initialize google analytics
ReactGA.initialize('UA-127752596-1')

const MainContent = props => {
	const [animateTransitions, setAnimateTransitions] = useState(false)
	const { location } = useContext(__RouterContext)

	// after mount start using transitions
	useEffect(() => {
		setAnimateTransitions(true)
	}, [])

	// send analytics pageview on route change
	useEffect(() => {
		// setAnimateTransitions(true)
		ReactGA.pageview(location.pathname)
	}, [location])

	let transitions
	if (location.pathname === '/about' || location.pathname === '/') {
		const fullyTransitioned = { opacity: 1, transform: 'translate3d(0%,0,0)' }
		transitions = useTransition(location, location => location.pathname, {
			from: animateTransitions
				? { opacity: 1, transform: 'translate3d(0,99%,0)' }
				: fullyTransitioned,
			enter: fullyTransitioned,
			leave: { opacity: 0, transform: 'translate3d(0, -50%,0)' },
			// reverse: true
		})
	} else {
		const fullyTransitioned = { opacity: 1, transform: 'translate3d(0%,0,0)' }
		transitions = useTransition(location, location => location.pathname, {
			from: animateTransitions
				? { opacity: 1, transform: 'translate3d(99%,0,0)' }
				: fullyTransitioned,
			enter: fullyTransitioned,
			leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
			// reverse: true
		})
	}
	// let transitions = useTransition(location, location => location.pathname, {
	// 	enter: [
	// 		{ opacity: 0, transform: 'scale(0.8) translatex(`100%)' },
	// 		{ opacity: 1, transform: 'scale(1.0) translatex(0%)' }
	// 	],
	// 	leave: [
	// 		{ opacty: 1, transform: 'scale(0.8) translatex(0%)' },
	// 		{ opacity: 1, transform: 'scale(0.8) translatex(-100%)' }
	// 	],
	// 	from: { opacity: 0, transform: 'scale(0.0) translatex(0%)' },
	// 	config: {
	// 		duration: 400
	// 	}
	// })
	return transitions.map(transition => (
		<animated.div
			key={transition.key}
			style={transition.props}
			className="page-wrap"
		>
			<div className="page-wrap-inner">
				<Switch location={transition.item}>
					<Route
						path="/"
						exact
						render={() => (
							<HomePage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/why"
						render={() => (
							<WhyPage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/how"
						render={() => (
							<HowPage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/what"
						render={() => (
							<WhatPage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/who"
						render={() => (
							<WhoPage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/upkeep"
						render={() => (
							<WhenPage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/about"
						render={() => (
							<AboutPage {...props} animateTransitions={animateTransitions} />
						)}
					/>
					<Route
						path="/full-form"
						render={() => (
							<FullFormPage
								{...props}
								animateTransitions={animateTransitions}
							/>
						)}
					/>
				</Switch>
			</div>
		</animated.div>
	))
}

const App = () => {
	const [ourFormData, setOurFormData] = useState({
		why: {
			question: 'Does your current home have fire sprinklers?',
		},
		how: {
			question: 'Do you live with any of the following?',
		},
		what: {
			question: 'Are you planning on building a home?',
		},
		who: {
			question: 'What type of home do you live in?',
		},
		when: {
			question: 'What is the water source in your home?',
		},
	})
	const [dropletState, setDropletState] = useState([0, 0, 0, 0, 0])
	const [scrollbarWidth, setScrollbarWidth] = useState(0)
	const [showMobileMenu, setShowMobileMenu] = useState()
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	// console.log('ourFormData', ourFormData)
	useEffect(() => {
		// set scrollbar width
		setScrollbarWidth(getScrollbarWidth())
		// class to hide outlines if not using keyboard
		const { body } = document
		body.addEventListener('mousedown', () => body.classList.add('using-mouse'))
		body.addEventListener('keydown', () => body.classList.remove('using-mouse'))
		// update on window resize
		window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
	}, [])
	return (
		<BrowserRouter>
			<div className="app">
				<Header
					dropletState={dropletState}
					setShowMobileMenu={setShowMobileMenu}
					showMobileMenu={showMobileMenu}
					windowWidth={windowWidth}
				/>
				<div className="main-content">
					<MainContent
						setDropletState={setDropletState}
						scrollbarWidth={scrollbarWidth}
						ourFormData={ourFormData}
						setOurFormData={setOurFormData}
					/>
					<MobileMenu
						showMobileMenu={showMobileMenu}
						setShowMobileMenu={setShowMobileMenu}
					/>
				</div>
			</div>
		</BrowserRouter>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
