import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

const ButtonWrap = (props, WrapEl) => {
	const [hover, setHover] = useState()
	const hoverState = useSpring({
		transform: hover
			? 'translate3d(0px, -1px, 0px) scale(1.005`)'
			: 'translate3d(0px, 0px, 0px) scale(1)',
		boxShadow: !hover
			? '-1px 10px 45px 0px rgba(0, 0, 0, 0)'
			: '-1px 10px 45px 0px rgba(0, 0, 0, 0.15)',
		config: {
			duration: 30
		}
	})
	return (
		<WrapEl
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			style={hoverState}
			{...props}
		>
			{props.children}
		</WrapEl>
	)
}

export const ButtonLink = props => {
	return ButtonWrap(props, animated(Link))
}

export const Button = props => {
	return ButtonWrap(props, animated.button)
}

export const ButtonA = props => {
	return ButtonWrap(props, animated.a)
}
