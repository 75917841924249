import React, { useEffect } from 'react'

import { ButtonLink } from '../components/button'
// import { Link } from 'react-router-dom'

import rainBackgroundWhite from '../images/background-white-rain.jpg'
import rainBackgroundBlue from '../images/background-blue-rain.jpg'
import warningRoll from '../images/warning-roll.jpg'
// import sprinklerPlan from '../images/sprinkler-plan.jpg'

const WhenLongContent = ({ setDropletState }) => {
	useEffect(() => {
		setDropletState && setDropletState([0, 0, 0, 0, 0])
	}, [])
	return (
		<div
			className="default-page about-long-content"
			style={{ backgroundImage: `url(${rainBackgroundWhite})` }}
		>
			<section className="def-lead">
				<div className="main">
					<div className="inner-wrap">
						<div className="section-header">
							<h2 className="section-heading">
								How do you maintain{' '}
								<strong>residential fire sprinklers?</strong>
							</h2>
							<p>Home fire sprinklers are reliable, and maintenance is easy.</p>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float">
				<div className="main">
					<div className="inner-wrap">
						<div className="vf-vid vf-img">
							<img src={warningRoll} alt="" />
						</div>
						<div className="vf-content">
							<ul>
								<li>
									<strong>
										Do not hang anything from the sprinkler heads.
									</strong>{' '}
									Hanging items can accidentally trigger some sprinkler heads or
									block their water delivery.
								</li>
								<li>
									<strong>Do not paint the sprinkler heads – at all.</strong>{' '}
									Paint can insulate the heat-responsive element, delaying
									sprinkler activation, or gum up the deflector that spreads the
									water across the room when they deploy.{' '}
								</li>
								<li>
									<strong>Don’t damage the sprinkler heads.</strong> Head guards
									can be installed in areas like unfinished basements where
									sprinkler heads are exposed, to protect against accidental
									bumps.
								</li>
								<li>
									<strong>
										Don’t block the sprinkler heads with furniture or other
										objects.
									</strong>
								</li>
								<li>
									<strong>
										Don’t let sprinkler pipes be exposed to temperatures below
										40° F.
									</strong>
								</li>
								<li>
									<strong>Don’t turn the system off.</strong> The leading cause
									of sprinkler system failure is a closed control valve that
									blocks the water from flowing during an emergency. Keep this
									valve open at all times unless the system is taken offline for
									a specific reason – and then make sure it’s turned on again.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float vf-left">
				<div className="main">
					<div className="inner-wrap">
						<div className="vf-vid">
							<div className="responsive-embed">
								<iframe
									title="Buy Time, Buy Life"
									loading="lazy"
									src="https://www.youtube.com/embed/2X0qfe7CfE8?rel=0"
									frameBorder="0"
									allowFullScreen
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
						<div className="vf-content">
							<h3>
								That said, there are a few "dos" if you want to make sure your
								sprinklers working at all times:
							</h3>
							<ul>
								<li>
									<strong>
										NFPA recommends that you look over the sprinkler system on a
										monthly basis.{' '}
									</strong>
									Simply make sure that nothing is blocking the sprinklers or
									interfering with each head’s spray pattern.{' '}
								</li>
								<li>
									<strong>
										If your system relies on a water tank to deliver water,
										check to make sure the tank is full once per month.
									</strong>{' '}
									You can also install water-level alarms. Residents who rely on
									a municipal water supply don’t need to worry about this.{' '}
								</li>
								<li>
									<strong>
										If your system uses a pump to pressurize the water, test it
										every month to make sure it works and doesn’t trip the
										circuit breaker.
									</strong>{' '}
									This can be as easy as quickly turning it on and off.{' '}
								</li>
								<li>
									<strong>
										Test your waterflow device – an alarm that notifies you and
										any monitoring company that the sprinkler is active – every
										6 months.
									</strong>{' '}
									If your system is monitored by the fire department or an
									outside service, notify them before you do this.{' '}
								</li>
								<li>
									<strong>Once per year, do a flow test</strong> – which
									basically involves opening a test connection to make sure the
									water flows.
								</li>
							</ul>
							<p>
								The important thing to remember is that these actions are NFPA
								<i> recommendations</i>. As long as a system is properly
								installed and you avoid doing things that can impair the
								sprinklers, it is designed to
								<span className="p-cite">
									{' '}
									work.
									<span className="the-citations">
										<sup>1</sup>
										<sup>2</sup>
										<sup>3</sup>
									</span>
								</span>
							</p>
						</div>
					</div>
				</div>
			</section>

			<section
				className="blue-two-col"
				style={{ backgroundImage: `url(${rainBackgroundBlue})` }}
			>
				<div className="main">
					<div className="inner-wrap">
						<h2>
							Winterizing sprinkler systems – often through smart design – is
							key
						</h2>
						<div className="two-col-content">
							<div>
								<p>
									The vast majority of home sprinklers are "wet" systems,
									meaning water always sits in the network of pipes. To keep
									water flowing and prevent freeze damage, you must make sure
									the pipe is not exposed to temperatures below 40°
									<span className="p-cite">
										{' '}
										F.
										<span className="the-citations">
											<sup>4</sup>
										</span>
									</span>
								</p>
								<p>
									Similar to protecting your home plumbing system, you must
									winterize the pipe to avoid freezing conditions.
								</p>
							</div>
							<div>
								<p>
									Any pipe that is run through non-climate-controlled areas like
									an attic should be adequately insulated – but many installers
									avoid this problem altogether by using sidewall sprinklers on
									the upper floors of a home. The pipe runs through the walls
									rather than having to be placed above the ceiling.
								</p>
								<p>
									If you plan to leave the home for a long period of time, set
									the thermostat to an energy-efficient setting to keep the
									system warm.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="narrow-jumble">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-red-headline">
							Residential fire sprinklers:{' '}
							<strong>An incredible history of reliability</strong>
						</h2>
						<p>
							Over two decades after the city of Scottsdale, Arizona passed an
							ordinance mandating sprinklers in homes, the local fire department
							surveyed residents who had sprinklers installed within two years
							of the law. The results?
						</p>
						<h3>
							<strong>100%</strong> of respondents said their sprinklers{' '}
							<strong>were still working</strong>
						</h3>
						<h3>
							<strong>Only 11%</strong> reported that the system had ever had
							<strong> any sort of maintenance</strong>
							<span className="p-cite">
								{' '}
								<strong>issue</strong>
								<span className="the-citations">
									<sup>5</sup>
								</span>
							</span>
						</h3>
						<p>
							Few home devices have a worry-free track record like that over 23
							years. By simply making sure not to block or paint sprinklers,
							keeping the control valve open, and doing at least an annual test
							and inspection, home sprinkler systems are designed to reliably
							protect your family for decades.
						</p>
						<ButtonLink to="/full-form" className="btn">
							Request more information, and get a quote from an experienced
							contractor ››
						</ButtonLink>
					</div>
				</div>
			</section>

			<section className="citations">
				<div className="main">
					<div className="inner-wrap">
						<h2>Sources:</h2>
						<ol>
							<li>
								<i>
									NFPA 13D: Standard for the Installation of Sprinkler Systems
									in One- and Two-Family Dwellings and Manufactured Homes.
								</i>{' '}
								National Fire Protection Association. 2019.
							</li>
							<li>“Fire Sprinkler Systems.” City of Scottsdale, AZ.</li>
							<li>
								“Home Fire Sprinkler Cost Assessment – 2013.” The Fire
								Protection Research Foundation.
							</li>
							<li>
								"NFPA 13D: Standard for the Installation of Sprinkler Systems in
								One- and Two-Family Dwellings and Manufactured Homes." National
								Fire Protection Association. 2019.
							</li>
							<li>
								Upham, Richard. "Residential Fire Sprinkler Reliability in homes
								older than 20 years old in Scottsdale, AZ." City of Scottsdale
								Fire Department.
							</li>
						</ol>
					</div>
				</div>
			</section>
		</div>
	)
}

export default WhenLongContent
