import React, { useEffect } from 'react'

import { ButtonLink } from '../components/button'

import rainBackgroundWhite from '../images/background-white-rain.jpg'
import rainBackgroundBlue from '../images/background-blue-rain.jpg'
import chartImage from '../images/chart-fire-causes.png'
import graphicHouse from '../images/graphic-house.png'

const WhyLongContent = ({ setDropletState }) => {
	useEffect(() => {
		setDropletState && setDropletState([0, 0, 0, 0, 0])
	}, [])
	return (
		<div
			className="default-page"
			style={{ backgroundImage: `url(${rainBackgroundWhite})` }}
		>
			<section className="def-lead">
				<div className="main">
					<div className="inner-wrap">
						<div className="section-header">
							<h2 className="section-heading">
								Why do you need <strong>fire sprinklers?</strong>
							</h2>
							<p>
								Beyond statistics, there is a simple truth about home fires:
								when they happen, they happen fast – and they can be deadly. The
								smoke alone kills the majority of people, often incapacitating
								"so quickly that people are overcome and can’t make it to an
								otherwise accessible exit." Ask yourself: can you wake in the
								middle of the night – choking, confused, and panicked – and get
								yourself, your family, and the pets out of the house in less
								than 5{' '}
								<span className="p-cite">
									minutes?
									<span className="the-citations">
										<sup>1</sup>
									</span>
								</span>
							</p>
							<h3 className="grey-black-headline">
								Fire sprinklers buy time. <strong>And time buys life.</strong>
							</h3>
							<h4>
								Did you know a civilian dies in a <br />
								home fire every 3 hours and 20
								<span className="p-cite">
									{' '}
									minutes?
									<span className="the-citations">
										<sup>2</sup>
									</span>
								</span>
							</h4>
						</div>
					</div>
				</div>
			</section>

			<section className="content-vid">
				<div className="main">
					<div className="inner-wrap">
						<div className="cv-content">
							<p>
								Fire sprinklers are the most powerful, cost-effective tool for
								saving your family and property from fire. On average, it takes
								just one fire sprinkler to control 92% of home fires. Yup, just
								one. And even when it can’t completely stop a fire, the system
								buys a family time to escape and the fire department time to
								<span className="p-cite">
									{' '}
									arrive.
									<span className="the-citations">
										<sup>3</sup>
									</span>
								</span>
							</p>
							<p>
								More and more local governments nationwide now require
								residential fire sprinklers. This is because modern homes aren’t
								necessarily safer homes. While overall US fire deaths have
								dropped dramatically in the past 40 years, the death rate per
								1,000 home fires actually increased in 2016 compared to 1980,
								largely due to the widespread use of synthetic materials in
								furnishings and lightweight construction materials like
								compressed I-Beams and lightweight{' '}
								<span className="p-cite">
									{' '}
									doors.
									<span className="the-citations">
										<sup>4</sup>
									</span>
								</span>
							</p>
						</div>
						<div className="cv-vid">
							<div className="responsive-embed">
								<iframe
									title="Buy Time, Buy Life"
									loading="lazy"
									src="https://www.youtube.com/embed/LsReYgKpHbE?rel=0"
									frameBorder="0"
									allowFullScreen
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="graphic-section">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							<strong>Cooking</strong> is the leading cause of home fires,{' '}
							<br />
							followed by heating and electrical systems.
						</h2>
						<figure>
							<img
								src={chartImage}
								alt="Leading fire causes for home fires, deaths, and injuries,
								2011-2015"
							/>
						</figure>
					</div>
				</div>
			</section>

			<section
				className="blue-two-col"
				style={{ backgroundImage: `url(${rainBackgroundBlue})` }}
			>
				<div className="main">
					<div className="inner-wrap">
						<h2>
							Aren’t smoke alarm systems and <br /> fire extinguishers{' '}
							<strong>enough?</strong>
						</h2>
						<div className="two-col-content">
							<div>
								<p>
									Smoke detectors and fire alarms are a valuable part of a
									home’s fire safety arsenal. Unfortunately, alarms only monitor
									for a fire and do nothing to stop it, and extinguishers may be
									used too late.
								</p>
								<p>
									Fire sprinklers take action to contain and suppress the fire.
								</p>
								<p>
									Most fatal home fires occur in the early morning hours between
									1 and 2 a.m., when people are typically sleeping. By the time
									a smoke detector or a fire alarm system sounds, and occupants
									wake up and move to escape … the fire may have had time to{' '}
									<span className="p-cite">
										{' '}
										spread.
										<span className="the-citations">
											<sup>5</sup>
										</span>
									</span>
								</p>
							</div>
							<div>
								<p>
									Fire extinguishers are another powerful tool, and they are
									present in 76% of American homes. However, they are only used
									in roughly 5% of fires. And when they are deployed, they only
									put out a fire about half the time. 20% of the time, they have
									"little or no
									<span className="p-cite">
										{' '}
										effect."
										<span className="the-citations">
											<sup>6</sup>
										</span>
									</span>
								</p>
								<p>
									While smoke detectors, fire alarms, and extinguishers should
									be in your home – and you should plan and practice with your
									family what to do in an emergency – the truth is, sprinklers
									are the only solution that proactively fights back against
									fire, buying you time to exit and limiting the damage.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="narrow-jumble">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-red-headline">
							Sprinklers are effective at controlling <br />
							fires <strong>92%</strong> of the time.
						</h2>
						<p>
							Why do you need fire sprinklers in your home? Simply put, fire
							sprinklers work. Sprinklered homes have an 81% lower civilian
							death rate in reported fires compared to homes without sprinklers.
							Injuries are 31% lower. There are 79% fewer firefighter deaths.
						</p>
						<p>
							And consider this: without fire sprinklers, the average cost to
							repair per fire incident $19,300.That number drops to $8,000 in a
							residence with sprinklers, a 58% percent
							<span className="p-cite">
								{' '}
								reduction.
								<span className="the-citations">
									<sup>7</sup>
								</span>
							</span>
						</p>
						<h2 className="grey-black-headline">
							Where have <strong>fire sprinklers</strong> really <br />
							made a difference?
						</h2>
						<div className="jumble-two-col">
							<img src={graphicHouse} alt="" className="jtc-img" />
							<div className="jtc-content">
								<p>
									Consider the city of Scottsdale, Arizona. In 1985, the city
									required all new buildings and major renovations to have an
									automatic fire sprinkler system installed. In the following 15
									years, not a single person died in a sprinklered home vs. 13
									fatalities in unprotected homes.
								</p>
								<p>
									The city reduced its per capita fire damage to just $7.31 by
									2015 – nearly one-fourth the national average and one-fifth of
									the average for the Western
									<span className="p-cite">
										{' '}
										US.
										<span className="the-citations">
											<sup>8</sup>
										</span>
									</span>
								</p>
								<p>
									Over several decades, six cities and towns in Bucks County, PA
									also passed various ordinances requiring home fire sprinklers.
									The result?
								</p>
							</div>
						</div>
						<h3>
							<strong>Zero fire deaths</strong> in sprinklered homes over 22
							years and a <strong>93%</strong> average reduction in property
							<span className="p-cite">
								{' '}
								damage.
								<span className="the-citations">
									<sup>9</sup>
								</span>
							</span>
						</h3>
						<ButtonLink to="/how" className="btn">
							Learn how fire sprinklers work ››
						</ButtonLink>
					</div>
				</div>
			</section>

			<section className="citations">
				<div className="main">
					<div className="inner-wrap">
						<h2>Sources:</h2>
						<ol>
							<li>
								The National Fire Protection Association. "Reporter's Guide: The
								consequences of fire."
							</li>
							<li>
								Evarts, Ben. "Fire Loss in the United States During 2017."
								National Fire Protection Association.
							</li>
							<li>
								Ahrens, Marty. "U.S. Experience with Sprinklers." National Fire
								Protection Association.
							</li>
							<li>
								Ahrens, Marty. "Home Structure Fires." National Fire Protection
								Association.
							</li>
							<li>
								"Fatal Fires in Residential Buildings (2014-2016)." Federal
								Emergency Management Agency (FEMA).
							</li>
							<li>
								Ahrens, Marty. "Home Structure Fires." National Fire Protection
								Association.
							</li>
							<li>
								Ahrens, Marty. "Home Structure Fires." National Fire Protection
								Association.
							</li>
							<li>Ford, Beverly. "Desert Success." NFPA Journal. </li>
							<li>
								"Communities with Home Fire Sprinklers: The Experience in Bucks
								County, Pennsylvania." Home Fire Sprinkler Coalition and Fire
								Planning Associates, Inc.
							</li>
						</ol>
					</div>
				</div>
			</section>
		</div>
	)
}

export default WhyLongContent
