import React, { useEffect } from 'react'

import { ButtonA } from '../components/button'

import rainBackgroundWhite from '../images/background-white-rain.jpg'
// import rainBackgroundBlue from '../images/background-blue-rain.jpg'
// import chartImage from '../images/chart-fire-causes.png'
import nfsaLogo from '../images/nfsa-logo.png'

const AboutPage = ({ setDropletState }) => {
	useEffect(() => {
		setDropletState && setDropletState([0, 0, 0, 0, 0])
	}, [])
	return (
		<div
			className="default-page about-page"
			style={{ backgroundImage: `url(${rainBackgroundWhite})` }}
		>
			<section className="def-lead">
				<div className="main">
					<div className="inner-wrap">
						<div className="section-header">
							<h2 className="section-heading">
								About the <strong>National Fire Sprinkler Association</strong>
							</h2>

							<p>
								Since 1905, the National Fire Sprinkler Association has
								advocated to protect lives and property through the widespread
								acceptance of the fire sprinkler concept. The NFSA accomplishes
								this through a variety of means, including:
							</p>
							<ul>
								<li>
									working closely with lawmakers on both state and local levels
									to advance life safety through the fire sprinkler concept;
								</li>
								<li>
									media appearances and live fire sprinkler demonstrations;
								</li>
								<li>training; and</li>
								<li>model code and standards development.</li>
							</ul>
							<p>
								Our diverse teams of code officials, engineers, attorneys, and
								tradespeople pull from a myriad of different skills and
								experiences to effectively promote the fire sprinkler industry
								locally, in every state, and on the national level.
							</p>
							<ButtonA
								href="https://nfsa.org/"
								className="btn"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more at NFSA.org
							</ButtonA>
							<img src={nfsaLogo} alt="" />
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default AboutPage
