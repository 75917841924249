import React, { useEffect } from 'react'

import { ButtonLink } from '../components/button'
import { Link } from 'react-router-dom'

import rainBackgroundWhite from '../images/background-white-rain.jpg'
import rainBackgroundBlue from '../images/background-blue-rain.jpg'
import contractorInstall from '../images/contractor-install.jpg'
import sprinklerPlan from '../images/sprinkler-plan.jpg'

const WhoLongContent = ({ setDropletState }) => {
	useEffect(() => {
		setDropletState && setDropletState([0, 0, 0, 0, 0])
	}, [])
	return (
		<div
			className="default-page about-long-content"
			style={{ backgroundImage: `url(${rainBackgroundWhite})` }}
		>
			<section className="def-lead">
				<div className="main">
					<div className="inner-wrap">
						<div className="section-header">
							<h2 className="section-heading">
								Who are <strong>fire sprinkler contractors?</strong>
							</h2>
							<p>
								Licensed fire sprinkler contractors are the backbone of the fire
								protection industry and your direct connection to a life-saving
								fire sprinkler system in your home. They’re the experts with the
								experience necessary to keep your home fire-safe.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="video-float">
				<div className="main">
					<div className="inner-wrap">
						<div className="vf-vid vf-img">
							<img src={contractorInstall} alt="" />
						</div>
						<div className="vf-content">
							<p>
								In most jurisdictions in the US, any company contracting to
								install, inspect, test, maintain, or service home fire sprinkler
								systems must meet rigorous requirements. These requirements vary
								from state to state. But typically, the fire and insurance
								professionals at your state fire marshal's office decide who's
								qualified.
							</p>
							<p>
								Licensed fire sprinkler contractors employ professional
								engineers and certified technicians with training and experience
								in the design and layout of fire sprinkler systems. Some
								specialize only in home fire sprinkler systems, while others are
								veteran designers of commercial and residential fire sprinklers
								of any scale or complexity – but all can capably oversee the
								safe, cost-effective, and code-compliant installation of fire
								sprinkler systems. They're expected to have a knowledge of
								subjects ranging from project management to recent developments
								in the fire codes.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="graphic-section">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							Contractors handle the <strong>complexity</strong>
						</h2>
						<figure>
							<img src={sprinklerPlan} alt="" />
							<figcaption>Example of a typical sprinkler plan.</figcaption>
						</figure>
					</div>
				</div>
			</section>

			<section className="graphic-section left-justify">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-black-headline">
							Why are <strong>licensing and bonding</strong> important?
						</h2>
						<p>Licensing and bonding protect homeowners.</p>
						<p>
							Licensing allows state and local governments to keep contractors
							accountable, ensuring that they have worker's compensation
							insurance, liability insurance, and the expertise needed to work
							safely on fire sprinkler systems. Bonding, a specialized form of
							insurance, takes this protection one step further by protecting
							you against losses if the contractor fails to finish installing
							the fire sprinkler system. And hiring unbonded professionals can
							void your insurance policy, increase your premiums, or make it
							more difficult to get insurance in the future.
						</p>
						<p>
							In short, licensed contractors know what they’re doing, meaning a
							safe and effective installation. And bonded contractors agree to
							meet their obligations – and their insurance company guarantees to
							repay you if they can't.
						</p>
					</div>
				</div>
			</section>

			<section
				className="blue-two-col"
				style={{ backgroundImage: `url(${rainBackgroundBlue})` }}
			>
				<div className="main">
					<div className="inner-wrap">
						<h2>
							My state doesn't require licensing – why shouldn't I hire a
							handyman?
						</h2>
						<div className="two-col-content">
							<div>
								<p>
									The National Fire Protection Association's standards for home
									fire sprinkler systems are widely embraced by state and local
									governments. Most home fire sprinkler systems are designed
									using guidelines from{' '}
									<i>
										NFPA 13D: Standard for the Installation of Sprinkler Systems
										in One- and Two-Family Dwellings and Manufactured Homes
									</i>
									.
								</p>
								<p>
									These guidelines matter: small differences in sprinkler system
									design or installation can render a fire sprinkler ineffective
									or totally useless. Amateur installers or unqualified plumbers
									may not understand the finer points – or even the basics – of
									fire sprinkler systems.
								</p>
							</div>
							<div>
								<p>
									Professional fire sprinkler contractors are not only
									well-versed in the characteristics of fire sprinkler heads,
									pressure and volume requirements, and other core features of
									home installations, they're also experienced in making fire
									sprinkler systems look as good as possible. They can
									efficiently conceal piping used in home sprinkler retrofits,
									minimize demolition and reconstruction, and leave your home
									looking as good as it did when they started – and much, much
									safer.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="narrow-jumble">
				<div className="main">
					<div className="inner-wrap">
						<h2 className="grey-red-headline">
							How can I find a <strong>licensed</strong> fire sprinkler
							contractor?
						</h2>
						<p>
							Always check your state's licensing requirements before hiring a
							contractor to design, install, repair, or maintain your fire
							sprinkler system. Your local or state fire marshal can tell you
							what local laws require.
						</p>
						<p>
							Some contractors list their certifications on their websites – but
							that's just a starting point. Check that those licenses are
							current with your state's licensing board, state fire marshal, or
							other government agency.
						</p>
						<p>
							<strong>Ask a potential contractor:</strong>
						</p>
						<ul>
							<li>
								Have you installed or retrofitted NFPA 13D systems before?
							</li>
							<li>Can you provide proof of licensing and bonding?</li>
							<li>
								Do you employ NICET-certified layout technicians or professional
								engineers?
							</li>
							<li>
								What parts of the project, if any, do you leave to
								subcontractors?
							</li>
						</ul>
						<p>
							One option to find a contractor is to check your state's directory
							of licensed sprinkler contractors. A much, much easier way is to{' '}
							<Link to="/full-form">fill out this form</Link> and we will put
							you in touch with a licensed, bonded, and thoroughly qualified
							sprinkler contractor with experience installing residential
							systems.
						</p>
						<h3>
							Licensed sprinkler contractors make sure you're getting what you
							pay for: expert design, careful installation, and, ultimately,
							peace of mind. They're worth choosing carefully.
						</h3>
						<ButtonLink to="/upkeep" className="btn">
							Maintaining home sprinkler systems is easy ››
						</ButtonLink>
					</div>
				</div>
			</section>
		</div>
	)
}

export default WhoLongContent
