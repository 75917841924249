import React, { useEffect, useState } from 'react'
import dlv from 'dlv'
import { Button } from '../components/button'
import { useTransition, animated } from 'react-spring'
import { TrailEls } from '../helpers'

import {
	WhyForm,
	HowForm,
	WhatForm,
	WhoForm,
	WhenForm,
	EmailForm,
	NameForm,
	ZipForm,
	PhoneForm,
} from '../forms.js'

const apiPath = '/wp-json/boost/v1/submit-entry'

const formSubmissionUrl = `https://dashboard.firesprinklersbuylife.com${apiPath}`

const FullFormPage = ({
	setDropletState,
	ourFormData,
	setOurFormData,
	animateTransitions,
}) => {
	useEffect(() => {
		setDropletState([1, 1, 1, 1, 1])
	}, [])

	const [isSubmitting, setIsSubmitting] = useState()

	const getAnswerVal = key => dlv(ourFormData, key)
	const whyAnswerVal = getAnswerVal('why.answer')
	const howAnswerVal = getAnswerVal('how.answer')
	const whatAnswerVal = getAnswerVal('what.answer')
	const whoAnswerVal = getAnswerVal('who.answer')
	const whenAnswerVal = getAnswerVal('when.answer')
	const emailAnswerVal = ourFormData.email
	const nameAnswerVal = ourFormData.name
	const zipAnswerVal = ourFormData.zip
	const phoneAnswerVal = ourFormData.phone

	const { formSubmitted } = ourFormData

	const continueForm = () => {
		let newFormData = JSON.parse(JSON.stringify(ourFormData))
		newFormData.formSubmitted = true
		setOurFormData(newFormData)
	}

	const handleServerError = () => {
		setIsSubmitting(false)
		alert(
			'Sorry! Something unexpected happened.\nIf this continues to happen, please email directly to info@nfsa.com'
		)
	}

	const submitForm = e => {
		e.preventDefault()
		// return if ajax submit in progress
		if (isSubmitting) {
			return
		}
		// console.log('is sending')
		const formObj = {
			name: nameAnswerVal,
			email: emailAnswerVal,
			zip: zipAnswerVal,
			phone: phoneAnswerVal,
			why: whyAnswerVal,
			how: howAnswerVal,
			what: whatAnswerVal,
			who: whoAnswerVal,
			when: whenAnswerVal,
			whyQuestion: dlv(ourFormData, 'why.question'),
			howQuestion: dlv(ourFormData, 'how.question'),
			whatQuestion: dlv(ourFormData, 'what.question'),
			whoQuestion: dlv(ourFormData, 'who.question'),
			whenQuestion: dlv(ourFormData, 'when.question'),
		}
		const formObjKeys = Object.keys(formObj)
		const validSubmission =
			formObjKeys.filter(key => formObj[key]).length === formObjKeys.length

		if (!validSubmission) {
			return alert('Please fill in all fields')
		}
		setIsSubmitting(true)
		// timeout for testing
		// setTimeout(continueForm, 600)
		// console.log('okay to send - sending', validSubmission)
		const fetchOptions = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(formObj),
		}

		fetch(formSubmissionUrl, fetchOptions)
			.then(response => response.json())
			.then(data => {
				if (data.success) {
					continueForm()
				} else {
					// console.warn(data)
					handleServerError()
				}
			})
			.catch(err => {
				// console.warn(err)
				handleServerError()
			})
	}

	const initialViewTransition = useTransition(!formSubmitted, null, {
		initial: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})
	const secondViewTransition = useTransition(formSubmitted, null, {
		from: { opacity: 0, transform: `scale(1.1)` },
		enter: { opacity: 1, transform: `scale(1)` },
		leave: { opacity: 0, transform: `scale(0.9)` },
	})

	return (
		<div className={`page page-full-form`}>
			{initialViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="page-sections view-one"
						>
							<div className="main ps-main">
								<div className="inner-wrap">
									<TrailEls
										animateTransitions={animateTransitions}
										items={[
											<div className="section-header">
												<h2 className="section-heading">
													Find Out How to Get{' '}
													<strong>Fire Sprinklers in Your Home</strong>
												</h2>
												<p>
													Fill out the remaining form and we'll provide you with
													a free home sprinkler installation guide. If you live
													in an existing single-family home or plan on building
													in the next year, a qualified sprinkler contractor
													will contact you with an estimate.
												</p>
											</div>,
											<div className="gradient-wrap">
												<form className="gw-inner" onSubmit={submitForm}>
													<div className="form-section">
														<NameForm
															answerVal={nameAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<EmailForm
															answerVal={emailAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<ZipForm
															answerVal={zipAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<PhoneForm
															answerVal={phoneAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<WhyForm
															answerVal={whyAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<HowForm
															answerVal={howAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<WhatForm
															answerVal={whatAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<WhoForm
															answerVal={whoAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-section">
														<WhenForm
															answerVal={whenAnswerVal}
															ourFormData={ourFormData}
															setOurFormData={setOurFormData}
														/>
													</div>
													<div className="form-button">
														<Button
															className="btn btn-white btn-submit"
															type="submit"
														>
															{isSubmitting && (
																<div className="button-spinner">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 16 16"
																	>
																		<path
																			fill="#0a4b6c"
																			d="M12.9 3.1C14.2 4.3 15 6.1 15 8c0 3.9-3.1 7-7 7s-7-3.1-7-7c0-1.9.8-3.7 2.1-4.9l-.8-.8C.9 3.8 0 5.8 0 8c0 4.4 3.6 8 8 8s8-3.6 8-8c0-2.2-.9-4.2-2.3-5.7l-.8.8z"
																		/>
																	</svg>
																</div>
															)}
															Submit ››
														</Button>
													</div>
												</form>
											</div>,
										]}
									/>
								</div>
							</div>
						</animated.div>
					)
			)}

			{secondViewTransition.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="page-sections view-two"
						>
							<div className="main ps-main">
								<div className="inner-wrap">
									<div className="gradient-wrap">
										<div className="gw-inner second">
											<h3>Thank you</h3>
											<div className="answer-exp">
												<p>
													Thank you for taking the time to learn about fire
													sprinklers. Over the next couple of days, be on the
													lookout for an email from us sharing a home fire
													sprinkler fact sheet. It provides much of the
													information presented on this website, enabling you to
													make the case for fire sprinklers to others –
													especially if you live in a multi-family building.
												</p>
												<p>
													If you live in a single-family home or plan on
													building in the next year, a qualified sprinkler
													contractor will contact you with an estimate.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</animated.div>
					)
			)}
		</div>
	)
}

export default FullFormPage
